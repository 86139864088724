import {ContentData, NavigationData, SectionData} from "../../../../data/types";
import {Renderer} from "../renderer";
import {Logger} from "../../../logger";

/**
 * Base class for all sections.
 */
abstract class BaseSection<Data extends SectionData | NavigationData> {
    protected data: Data;
    protected renderer: Renderer;
    protected logger: Logger;
    protected index: number;

    constructor(data: Data, renderer: Renderer, index: number) {
        this.data = data;
        this.renderer = renderer;
        this.logger = renderer.logger;
        this.index = index;
    }

    /**
     * Renders the section into an HTMLElement.
     * Must be implemented by subclasses.
     */
    abstract render(): HTMLElement | null;
}

/**
 * Renders a standard section with a title and nested content.
 */
class Section extends BaseSection<SectionData> {
    render(): HTMLElement | null {
        const section = document.createElement('section');

        const title = document.createElement('h2');
        title.textContent = this.data.title || `Section ${this.index + 1}`;
        section.appendChild(title);

        this.data.children.forEach((contentData: ContentData) => {
            const contentElement = this.renderer.contentFactory.createContentElement(contentData);
            if (contentElement) {
                const node = contentElement.create();
                if (node) section.appendChild(node);
            }
        });

        if (this.data.emotion) {
            this.renderer.applyEmotionAttributes(section, this.data.emotion);
        }

        return section;
    }
}

/**
 * Renders a navigation section with links.
 */
class NavigationSection extends BaseSection<NavigationData> {
    render(): HTMLElement | null {
        const nav = document.createElement('nav');
        nav.setAttribute('aria-label', 'Navigation');

        this.data.children.forEach((contentData: ContentData) => {
            const contentElement = this.renderer.contentFactory.createContentElement(contentData);
            if (contentElement) {
                const node = contentElement.create();
                if (node) nav.appendChild(node);
            }
        });

        if (this.data.emotion) {
            this.renderer.applyEmotionAttributes(nav, this.data.emotion);
        }

        return nav;
    }
}

/**
 * Factory class to create sections based on their type.
 */
export class SectionFactory {
    private renderer: Renderer;

    constructor(renderer: Renderer) {
        this.renderer = renderer;
    }

    /**
     * Creates a section instance based on the provided data and index.
     */
    createSection(data: SectionData | NavigationData, index: number): BaseSection<any> | null {
        switch (data.type) {
            case 'section':
                return new Section(data as SectionData, this.renderer, index);
            case 'navigation':
                return new NavigationSection(data as NavigationData, this.renderer, index);
            default:
                this.renderer.logger.warn(`Unknown section type: ${(data as any).type}`);
                return null;
        }
    }
}