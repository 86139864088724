// === custom/customModal.ts ===
import { customElement, property, state } from "lit/decorators";
import { css, html, LitElement, TemplateResult } from "lit";
import { BaseModal } from "./baseModal";

/**
 * CustomModal: A versatile modal dialog extending BaseModal.
 * Adds functionalities like footer slots, customizable animations, and enhanced accessibility.
 */
@customElement('custom-modal')
class CustomModal extends BaseModal {
    /**
     * Determines whether the modal can be closed by clicking the overlay.
     */
    @property({ type: Boolean }) closeOnOverlayClick: boolean = true;

    /**
     * Determines whether the modal can be closed by pressing the Escape key.
     */
    @property({ type: Boolean }) closeOnEscape: boolean = true;

    /**
     * Specifies the animation type for the modal.
     * Options: 'fade', 'slide', 'zoom', etc.
     */
    @property({ type: String }) animationType: 'fade' | 'slide' | 'zoom' = 'fade';

    /**
     * Controls whether the modal should autofocus on the first focusable element when opened.
     */
    @property({ type: Boolean }) autoFocus: boolean = true;

    /**
     * Tracks if the modal has been interacted with.
     * Used for advanced focus management if needed.
     */
    @state() private hasInteracted: boolean = false;

    /**
     * Defines the component's styles, including animations.
     */
    static styles = [
        BaseModal.styles,
        css`
            /* Animation Keyframes */
            @keyframes fadeIn {
                from { opacity: 0; }
                to { opacity: 1; }
            }
    
            @keyframes fadeOut {
                from { opacity: 1; }
                to { opacity: 0; }
            }
    
            @keyframes slideIn {
                from { transform: translateY(-100%); }
                to { transform: translateY(0); }
            }
    
            @keyframes slideOut {
                from { transform: translateY(0); }
                to { transform: translateY(-100%); }
            }
    
            @keyframes zoomIn {
                from { transform: scale(0.5); opacity: 0; }
                to { transform: scale(1); opacity: 1; }
            }
    
            @keyframes zoomOut {
                from { transform: scale(1); opacity: 1; }
                to { transform: scale(0.5); opacity: 0; }
            }
    
            /* Modal Animations */
            .modal.fade-in {
                animation: fadeIn 0.3s forwards;
            }
    
            .modal.fade-out {
                animation: fadeOut 0.3s forwards;
            }
    
            .modal.slide-in {
                animation: slideIn 0.3s forwards;
            }
    
            .modal.slide-out {
                animation: slideOut 0.3s forwards;
            }
    
            .modal.zoom-in {
                animation: zoomIn 0.3s forwards;
            }
    
            .modal.zoom-out {
                animation: zoomOut 0.3s forwards;
            }
    
            /* Footer Styles */
            .footer {
                display: flex;
                justify-content: flex-end;
                gap: var(--spacing-sm, 0.5rem);
                margin-top: var(--spacing-md, 1rem);
            }
    
            /* Responsive Design */
            @media (max-width: 600px) {
                .modal {
                    width: 95%;
                    padding: var(--spacing-sm, 1rem);
                }
    
                .footer {
                    flex-direction: column;
                    align-items: stretch;
                }
    
                .footer button {
                    width: 100%;
                }
            }
        `,
    ];

    /**
     * Initializes the component and sets up event listeners.
     */
    constructor() {
        super();
        this.setupAnimations();
    }

    /**
     * Sets up modal animations based on the specified animation type.
     */
    private setupAnimations(): void {
        this.addEventListener('modal-opened', this.handleAnimationStart.bind(this));
        this.addEventListener('modal-closed', this.handleAnimationEnd.bind(this));
    }

    /**
     * Handles the start of the modal opening animation.
     */
    private handleAnimationStart(): void {
        const modal = this.shadowRoot?.querySelector('.modal');
        if (modal) {
            switch (this.animationType) {
                case 'fade':
                    modal.classList.add('fade-in');
                    break;
                case 'slide':
                    modal.classList.add('slide-in');
                    break;
                case 'zoom':
                    modal.classList.add('zoom-in');
                    break;
                default:
                    modal.classList.add('fade-in');
            }
        }
    }

    /**
     * Handles the end of the modal closing animation.
     */
    private handleAnimationEnd(): void {
        const modal = this.shadowRoot?.querySelector('.modal');
        if (modal) {
            switch (this.animationType) {
                case 'fade':
                    modal.classList.remove('fade-in');
                    modal.classList.add('fade-out');
                    break;
                case 'slide':
                    modal.classList.remove('slide-in');
                    modal.classList.add('slide-out');
                    break;
                case 'zoom':
                    modal.classList.remove('zoom-in');
                    modal.classList.add('zoom-out');
                    break;
                default:
                    modal.classList.remove('fade-in');
                    modal.classList.add('fade-out');
            }
        }
    }

    /**
     * Renders the modal content, including header, body, and footer slots.
     * @returns The TemplateResult for rendering.
     */
    render(): TemplateResult {
        return html`
            ${this.isOpen ? html`
                <div
                    class="overlay ${this.isOpen ? 'active' : ''}"
                    @click="${this.handleOverlayClick}"
                    role="presentation"
                ></div>
                <div
                    class="modal ${this.animationType}-in"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-title"
                    @click="${this.stopPropagation}"
                    @animationend="${this.handleAnimationComplete}"
                >
                    <div class="header">
                        <h2 id="modal-title" class="title">${this.title}</h2>
                        <button
                            class="close-button"
                            aria-label="Close Modal"
                            @click="${this.closeModal}"
                        >
                            &times;
                        </button>
                    </div>
                    <div class="content">
                        <slot name="content"></slot>
                    </div>
                    <div class="footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
            ` : ''}
        `;
    }

    /**
     * Handles clicks on the overlay to close the modal if allowed.
     * @param event - The mouse event.
     */
    private handleOverlayClick(event: MouseEvent): void {
        if (this.closeOnOverlayClick) {
            this.closeModal();
        }
    }

    /**
     * Prevents click events from propagating to the overlay.
     * @param event - The mouse event.
     */
    private stopPropagation(event: MouseEvent): void {
        event.stopPropagation();
    }

    /**
     * Handles the completion of animations to manage modal states.
     * @param event - The animation event.
     */
    private handleAnimationComplete(event: AnimationEvent): void {
        const modal = this.shadowRoot?.querySelector('.modal');
        if (!modal) return;

        if (event.animationName === 'fadeOut' ||
            event.animationName === 'slideOut' ||
            event.animationName === 'zoomOut') {
            this.isOpen = false;
            modal.classList.remove('fade-out', 'slide-out', 'zoom-out');
        }
    }

    /**
     * Closes the modal by setting isOpen to false.
     */
    private closeModal(): void {
        if (!this.isOpen) return;
        if (this.closeOnEscape) {
            this.isOpen = false;
        }
    }

    /**
     * Overrides the BaseModal's closeModal to include additional logic if needed.
     */
    protected override closeModal(): void {
        super.closeModal();
    }

    /**
     * Opens the modal programmatically.
     */
    public openModal(): void {
        if (this.isOpen) return;
        this.isOpen = true;
        this.dispatchEvent(new CustomEvent('modal-opened', { bubbles: true, composed: true }));
    }

    /**
     * Closes the modal programmatically.
     */
    public closeModalExternally(): void {
        if (!this.isOpen) return;
        this.isOpen = false;
        this.dispatchEvent(new CustomEvent('modal-closed', { bubbles: true, composed: true }));
    }

    /**
     * Handles keyboard events for enhanced accessibility.
     * @param event - The keyboard event.
     */
    protected override handleKeyDown(event: KeyboardEvent): void {
        if (this.closeOnEscape && event.key === 'Escape') {
            event.preventDefault();
            this.closeModal();
        }
        super.handleKeyDown(event);
    }

    /**
     * Sets focus to the modal when it opens if autoFocus is enabled.
     */
    protected override firstUpdated(): void {
        super.firstUpdated();
        if (this.isOpen && this.autoFocus) {
            const firstFocusable = this.shadowRoot?.querySelector<HTMLElement>('.modal button, .modal [href], .modal input, .modal select, .modal textarea, .modal [tabindex]:not([tabindex="-1"])');
            firstFocusable?.focus();
        }
    }
}
