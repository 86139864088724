import {customElement, property, state} from "lit/decorators";
import {css, html, LitElement, TemplateResult} from "lit";
import {QuizQuestion} from "../../../data/types";
import {Logger} from "./baseModal";

/**
 * CustomQuiz: Manages a quiz composed of multiple questions.
 */
@customElement('custom-quiz')
class CustomQuiz extends LitElement {
    @property({type: Array}) questions: QuizQuestion[] = [];

    @state() private currentQuestionIndex: number = 0;
    @state() private score: number = 0;
    @state() private showResult: boolean = false;

    static styles = css`
        .quiz-container {
            max-width: 800px;
            margin: 0 auto;
            padding: 1rem;
            border: 2px solid #007bff;
            border-radius: 0.5rem;
            background-color: #f9f9f9;
        }

        .result {
            font-size: 1.5rem;
            color: green;
            text-align: center;
            margin-top: 2rem;
        }

        .restart-button {
            display: block;
            margin: 1rem auto 0;
            padding: 0.75rem 1.5rem;
            border: none;
            border-radius: 0.25rem;
            background-color: #28a745;
            color: #fff;
            font-size: 1rem;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }

        .restart-button:hover {
            background-color: #218838;
        }
    `;

    private logger = new Logger('CustomQuiz');

    render(): TemplateResult {
        if (this.showResult) {
            return html`
                <div class="quiz-container">
                    <div class="result">You scored ${this.score} out of ${this.questions.length}!</div>
                    <button class="restart-button" @click="${this.restartQuiz}">Restart Quiz</button>
                </div>
            `;
        }

        const currentQuestion = this.questions[this.currentQuestionIndex];

        return html`
            <div class="quiz-container">
                <custom-quiz-question
                        .questionData="${currentQuestion}"
                        .questionNumber="${this.currentQuestionIndex + 1}"
                        .totalQuestions="${this.questions.length}"
                        @answer-submitted="${this.handleAnswerSubmitted}"
                        @question-skipped="${this.handleQuestionSkipped}"
                ></custom-quiz-question>
            </div>
        `;
    }

    private handleAnswerSubmitted(event: CustomEvent): void {
        if (event.detail.isCorrect) {
            this.score += 1;
            this.logger.info(`Correct answer for question ${this.currentQuestionIndex + 1}`);
        } else {
            this.logger.info(`Incorrect answer for question ${this.currentQuestionIndex + 1}`);
        }

        this.nextQuestion();
    }

    private handleQuestionSkipped(): void {
        this.logger.info(`Question ${this.currentQuestionIndex + 1} skipped.`);
        this.nextQuestion();
    }

    private nextQuestion(): void {
        if (this.currentQuestionIndex < this.questions.length - 1) {
            this.currentQuestionIndex += 1;
        } else {
            this.endQuiz();
        }
    }

    private endQuiz(): void {
        this.showResult = true;
        this.dispatchEvent(
            new CustomEvent('quiz-completed', {
                detail: {score: this.score, total: this.questions.length},
                bubbles: true,
                composed: true,
            }),
        );
        this.logger.info(`Quiz completed with score ${this.score}/${this.questions.length}`);
    }

    private restartQuiz(): void {
        this.currentQuestionIndex = 0;
        this.score = 0;
        this.showResult = false;
        this.logger.info('Quiz restarted');
    }
}