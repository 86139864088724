// === emotion.ts ===
// src/app/emotions.ts

import { Logger } from './logger';

export class EmotionalInteractor {
    logger: Logger;

    constructor() {
        this.logger = new Logger('EmotionalInteractor');
        this.init();
    }

    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.setupEmotionListeners();
        });
    }

    setupEmotionListeners() {
        const emotionElements = document.querySelectorAll<HTMLElement>('[data-emotion-valence][data-emotion-arousal]');

        emotionElements.forEach((element) => {
            const valence = parseFloat(element.getAttribute('data-emotion-valence') || '0');
            const arousal = parseFloat(element.getAttribute('data-emotion-arousal') || '0.5');
            const discreteEmotions = Array.from(element.classList)
                .filter(
                    (cls) =>
                        cls.startsWith('emotion-') &&
                        ![
                            'emotion-valence-high',
                            'emotion-valence-low',
                            'emotion-valence-neutral',
                            'emotion-arousal-high',
                            'emotion-arousal-low',
                            'emotion-arousal-medium',
                        ].includes(cls)
                )
                .map((cls) => cls.replace('emotion-', ''));

            this.applyEmotionStyles(valence, arousal, discreteEmotions, element);
        });
    }

    applyEmotionStyles(valence: number, arousal: number, discreteEmotions: string[], element: HTMLElement) {
        const valenceHigh = 0.5;
        const valenceLow = -0.5;
        const arousalHigh = 0.7;
        const arousalLow = 0.3;

        const root = document.documentElement;

        if (valence > valenceHigh) {
            root.setAttribute('data-emotion-valence', 'positive');
        } else if (valence < valenceLow) {
            root.setAttribute('data-emotion-valence', 'negative');
        } else {
            root.setAttribute('data-emotion-valence', 'neutral');
        }

        if (arousal > arousalHigh) {
            root.setAttribute('data-emotion-arousal', 'high');
        } else if (arousal < arousalLow) {
            root.setAttribute('data-emotion-arousal', 'low');
        } else {
            root.setAttribute('data-emotion-arousal', 'medium');
        }

        discreteEmotions.forEach((emotion) => {
            root.classList.add(`emotion-${emotion}`);
            this.logger.info(`Applied discrete emotion class: emotion-${emotion}`);
        });

        this.logger.info(
            `Applied CSS variables based on valence: ${valence} (${root.getAttribute(
                'data-emotion-valence'
            )}), arousal: ${arousal} (${root.getAttribute('data-emotion-arousal')})`
        );
    }
}
