// src/app/routes.ts

export const routes = {
    chapter: {
        prefix: '/book/chapter/',
        pattern: /^\/book\/chapter\/(\d{2})/,
        generatePath: (chapterNumber: number | string) => `/book/chapter/${padChapterNumber(chapterNumber)}/`,
    },
};

function padChapterNumber(number: number | string): string {
    return number.toString().padStart(2, '0');
}

export function navigateToRoute(routeName: string, params: any) {
    if (!(routes as any)[routeName]) {
        console.error(`Route "${routeName}" is not defined.`);
        return;
    }

    let path;
    if (routeName === 'chapter') {
        path = routes[routeName].generatePath(params);
    }

    if (path) {
        window.location.href = path;
    } else {
        console.error(`Unable to generate path for route "${routeName}" with params:`, params);
    }
}

