// === custom/customQuizQuestion.ts ===
import { customElement, property, state } from "lit/decorators";
import { css, html, LitElement, TemplateResult } from "lit";
import { QuizQuestion } from "../../../data/types";
import { Logger } from "./baseModal";

/**
 * CustomQuizQuestion: Represents an individual quiz question.
 */
@customElement('custom-quiz-question')
class CustomQuizQuestion extends LitElement {
    @property({ type: Object }) questionData!: QuizQuestion;
    @property({ type: Number }) questionNumber!: number;
    @property({ type: Number }) totalQuestions!: number;

    @state() private selectedOption: number | null = null;
    @state() private answered: boolean = false;
    @state() private isCorrect: boolean = false;

    static styles = css`
        .question-container {
            border: 2px solid var(--color-primary, #007bff);
            border-radius: var(--border-radius-md, 0.5rem);
            padding: var(--spacing-md, 1rem);
            background-color: var(--color-bg-content, #f9f9f9);
            transition: background-color var(--transition-speed-fast, 0.3s) ease;
        }

        .question-number {
            font-size: var(--font-size-caption, 0.9rem);
            color: var(--color-text-alt, #555);
            margin-bottom: var(--spacing-sm, 0.5rem);
        }

        .question-text {
            font-size: var(--font-size-body, 1rem);
            margin-bottom: var(--spacing-md, 1rem);
            color: var(--color-text-main, #333);
        }

        .options {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .option {
            margin-bottom: var(--spacing-xs, 0.25rem);
        }

        .option label {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: var(--spacing-xs, 0.25rem);
            border-radius: var(--border-radius-sm, 0.25rem);
            transition: background-color var(--transition-speed-fast, 0.3s) ease;
        }

        .option input {
            margin-right: var(--spacing-xs, 0.25rem);
            cursor: pointer;
        }

        .option:hover,
        .option:focus-within {
            background-color: var(--color-bg-valence-neutral, #e0e0e0);
        }

        .feedback {
            margin-top: var(--spacing-md, 1rem);
            font-size: var(--font-size-body, 1rem);
            font-weight: bold;
            animation: fadeIn 0.3s ease-in-out;
        }

        .feedback.correct {
            color: var(--color-positive, green);
        }

        .feedback.incorrect {
            color: var(--color-negative, red);
        }

        .buttons {
            margin-top: var(--spacing-md, 1rem);
            display: flex;
            gap: var(--spacing-sm, 0.5rem);
        }

        button {
            padding: var(--spacing-sm, 0.5rem) var(--spacing-md, 1rem);
            border: none;
            border-radius: var(--border-radius-sm, 0.25rem);
            background-color: var(--color-primary, #007bff);
            color: var(--color-text-content, #fff);
            cursor: pointer;
            transition: background-color var(--transition-speed-fast, 0.3s) ease;
            font-size: var(--font-size-button, 1rem);
        }

        button:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }

        button:hover:not(:disabled),
        button:focus:not(:disabled) {
            background-color: var(--color-primary-dark, #0056b3);
        }

        @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
        }
    `;

    private logger = new Logger('CustomQuizQuestion');

    render(): TemplateResult {
        return html`
            <div
                    class="question-container"
                    role="group"
                    aria-labelledby="question-text"
            >
                <div class="question-number">Question ${this.questionNumber} of ${this.totalQuestions}</div>
                <div id="question-text" class="question-text">${this.questionData.question}</div>
                <ul class="options" role="radiogroup" aria-label="Quiz Options">
                    ${this.questionData.options.map((option: string, index: number) => html`
                        <li class="option">
                            <label>
                                <input
                                    type="radio"
                                    name="option"
                                    .value="${index}"
                                    @change="${() => this.selectOption(index)}"
                                    ?checked="${this.selectedOption === index}"
                                    ?disabled="${this.answered}"
                                    aria-checked="${this.selectedOption === index}"
                                />
                                ${option}
                            </label>
                        </li>
                    `)}
                </ul>
                ${this.answered ? this.renderFeedback() : ''}
                <div class="buttons">
                    <button @click="${this.submitAnswer}" ?disabled="${this.selectedOption === null || this.answered}">
                        Submit
                    </button>
                    ${!this.answered ? html`
                        <button @click="${this.skipQuestion}">
                            Skip
                        </button>
                    ` : ''}
                </div>
            </div>
        `;
    }

    private renderFeedback(): TemplateResult {
        return html`
            <div class="feedback ${this.isCorrect ? 'correct' : 'incorrect'}">
                ${this.isCorrect
            ? '✅ Correct!'
            : `❌ Incorrect. The correct answer was "${this.questionData.options[this.questionData.correctIndex]}".`}
            </div>
        `;
    }

    private selectOption(index: number): void {
        if (this.answered) return;
        this.selectedOption = index;
    }

    private submitAnswer(): void {
        if (this.selectedOption === null) return;
        this.isCorrect = this.selectedOption === this.questionData.correctIndex;
        this.answered = true;
        this.logger.info(`Answer submitted: ${this.isCorrect ? 'Correct' : 'Incorrect'}`);
        this.dispatchEvent(
            new CustomEvent('answer-submitted', {
                detail: { isCorrect: this.isCorrect },
                bubbles: true,
                composed: true,
            }),
        );
    }

    private skipQuestion(): void {
        this.answered = true;
        this.isCorrect = false;
        this.logger.info('Question skipped.');
        this.dispatchEvent(
            new CustomEvent('question-skipped', {
                bubbles: true,
                composed: true,
            }),
        );
    }
}
