// === src/app/content/contentFactory.ts ===

import {
    ButtonData,
    ContentData,
    CustomCarouselData,
    CustomModalData,
    CustomProgressData,
    CustomQuizData,
    CustomRatingData,
    CustomTooltipData,
    DialogueData,
    EmphasisData,
    FigureData,
    LinkData,
    ListData,
    ListItem,
    ParagraphData,
    RhythmData,
    TextData,
    WhisperData,
    CustomPuzzleData,
    CustomReflectionData,
    SectionData,
    NavigationData,
    InteractiveElementData,
    CarouselItemContentData,
    Emotion,
    InteractionType,
} from "../../../../data/types";
import {Renderer} from "../renderer";
import {Logger} from "../../../logger";

/**
 * Base class for all content elements.
 */
export abstract class BaseContentElement<Data extends ContentData> {
    protected data: Data;
    protected renderer: Renderer;
    protected logger: Logger;

    // To track visited nodes for cycle detection
    private static visitedNodes = new WeakSet<ContentData>();

    constructor(data: Data, renderer: Renderer) {
        if (BaseContentElement.visitedNodes.has(data)) {
            throw new Error(`Circular reference detected in content data: ${data.type}`);
        }
        BaseContentElement.visitedNodes.add(data);

        this.data = data;
        this.renderer = renderer;
        this.logger = renderer.logger; // Accessing the logger directly
    }

    /**
     * Creates the content element as a Node.
     * Must be implemented by subclasses.
     */
    abstract create(): Node | null;

    /**
     * Helper method to create a DOM element with optional attributes.
     * @param tag - The tag name of the element to create.
     * @param attributes - An object representing attribute key-value pairs.
     * @returns The created HTMLElement or Custom Element.
     */
    protected createElement<K extends keyof HTMLElementTagNameMap>(
        tag: K | `custom-${string}`,
        attributes?: { [key: string]: any }
    ): HTMLElement {
        // Verify if the custom element is defined
        if (tag.startsWith('custom-') && !customElements.get(tag)) {
            this.logger.warn(`Custom element <${tag}> is not defined.`);
            // Optionally, define a fallback or throw an error
            // For now, we'll proceed without throwing
        }

        const element = document.createElement(tag);

        if (attributes) {
            for (const [key, value] of Object.entries(attributes)) {
                if (key === 'className') {
                    element.className = value;
                } else if (key.startsWith('data-')) {
                    element.setAttribute(key, value);
                } else if (key in element) {
                    try {
                        // Type assertion for known properties
                        (element as any)[key] = value;
                    } catch (error) {
                        this.logger.warn(`Failed to set property "${key}" on <${tag}>: ${error}`);
                    }
                } else {
                    // For unknown properties, set as data attributes
                    element.setAttribute(key, value);
                }
            }
        }

        return element;
    }

    /**
     * Helper method to append child elements recursively.
     * Implements cycle detection to prevent infinite recursion.
     * @param parent - The parent Node to append children to.
     */
    protected appendChildren(parent: Node): void {
        if (!this.data.children || this.data.children.length === 0) return;

        this.data.children.forEach((childData: ContentData) => {
            try {
                const childElement = this.renderer.contentFactory.createContentElement(childData);
                if (childElement) {
                    const node = childElement.create();
                    if (node) {
                        parent.appendChild(node);
                    } else {
                        this.logger.warn(`Failed to create node for child type: ${childData.type}`);
                    }
                } else {
                    this.logger.warn(`Unknown child type: ${childData.type}`);
                }
            } catch (error) {
                this.logger.error(`Error creating child element of type "${childData.type}": ${error}`);
            }
        });
    }

    /**
     * Applies emotional attributes to the given element.
     * @param element - The HTMLElement to apply emotions to.
     * @param emotion - The Emotion object containing emotional attributes.
     */
    protected applyEmotionAttributes(element: HTMLElement, emotion: Emotion): void {
        if (!emotion) {
            this.logger.warn(`Emotion object is undefined or null.`);
            return;
        }
        this.renderer.applyEmotionAttributes(element, emotion);
    }
}

/**
 * Concrete Content Element Classes
 */

// Text Element
class TextElement extends BaseContentElement<TextData> {
    create(): Node | null {
        if (!this.data.text) {
            this.logger.warn('TextElement created without text content.');
            return null;
        }
        const text = String(this.data.text);
        const textNode = document.createTextNode(text);
        this.logger.debug(`Created TextElement with text: "${text}"`);
        return textNode;
    }
}

// Paragraph Element
class ParagraphElement extends BaseContentElement<ParagraphData> {
    create(): Node | null {
        const p = this.createElement('p');

        this.appendChildren(p);

        if (this.data.emotion) {
            this.applyEmotionAttributes(p, this.data.emotion);
        }

        this.logger.debug('Created ParagraphElement.');
        return p;
    }
}

// Dialogue Element - Uses <custom-dialogue>
class DialogueElement extends BaseContentElement<DialogueData> {
    create(): Node | null {
        const dialogue = this.createElement('custom-dialogue');

        // Validate required fields
        if (!this.data.speaker) {
            this.logger.warn('DialogueElement created without a speaker.');
            (dialogue as any).speaker = 'Unknown';
        } else {
            (dialogue as any).speaker = String(this.data.speaker);
        }

        (dialogue as any).lines = Array.isArray(this.data.lines) ? this.data.lines : [];

        console.log({dialogue})


        if (this.data.avatar) {
            (dialogue as any).avatar = String(this.data.avatar);
        }

        // Append children (lines)
        this.appendChildren(dialogue);

        // Apply emotion attributes if present
        if (this.data.emotion) {
            this.applyEmotionAttributes(dialogue, this.data.emotion);
        }

        this.logger.debug(`Created DialogueElement with speaker: "${(dialogue as any).speaker}"`);
        return dialogue;
    }
}

// Whisper Element - Uses <custom-whisper>
class WhisperElement extends BaseContentElement<WhisperData> {
    create(): Node | null {
        const whisper = this.createElement('custom-whisper');

        // Append children (whispered lines)
        this.appendChildren(whisper);

        // Apply emotion attributes if present
        if (this.data.emotion) {
            this.applyEmotionAttributes(whisper, this.data.emotion);
        }

        (whisper as any).text = this.data.text.map(d => d.text)

        this.logger.debug('Created WhisperElement.');
        return whisper;
    }
}

// Figure Element
class FigureElement extends BaseContentElement<FigureData> {
    create(): Node | null {
        if (!this.data.src) {
            this.logger.warn('FigureElement created without an image source.');
            return null;
        }

        const figure = this.createElement('figure');

        const img = this.createElement('img', {
            src: this.data.src,
            alt: this.data.alt ?? '',
            loading: this.data.loading ?? 'lazy',
        });

        // Validate image element
        if (!(img instanceof HTMLImageElement)) {
            this.logger.warn('Failed to create an image element for FigureElement.');
            return null;
        }

        figure.appendChild(img);

        if (this.data.caption) {
            const caption = this.createElement('figcaption');
            caption.textContent = String(this.data.caption);
            figure.appendChild(caption);
        }

        if (this.data.title) {
            const title = this.createElement('h3');
            title.textContent = String(this.data.title);
            figure.appendChild(title);
        }

        if (this.data.emotion) {
            this.applyEmotionAttributes(figure, this.data.emotion);
        }

        this.logger.debug(`Created FigureElement with src: "${img.src}"`);
        return figure;
    }
}

// Button Element
class ButtonElement extends BaseContentElement<ButtonData> {
    create(): Node | null {
        if (!this.data.text || !this.data.action) {
            this.logger.warn('ButtonElement created without text or action.');
            return null;
        }

        const button = this.createElement('button', {
            textContent: String(this.data.text),
            'data-action': String(this.data.action),
        });

        // Validate button element
        if (!(button instanceof HTMLButtonElement)) {
            this.logger.warn('Failed to create a button element.');
            return null;
        }

        // Add event listener for the action
        button.addEventListener('click', () => {
            this.logger.info(`Button action triggered: ${this.data.action}`);
            try {
                this.renderer.handleAction(this.data.action, this.data.payload);
            } catch (error) {
                this.logger.error(`Error handling button action "${this.data.action}": ${error}`);
            }
        });

        if (this.data.emotion) {
            this.applyEmotionAttributes(button, this.data.emotion);
        }

        this.logger.debug('Created ButtonElement.');
        return button;
    }
}

// Link Element
class LinkElement extends BaseContentElement<LinkData> {
    create(): Node | null {
        if (!this.data.href || !this.data.text) {
            this.logger.warn('LinkElement created without href or text.');
            return null;
        }

        const link = this.createElement('a', {
            href: String(this.data.href),
            textContent: String(this.data.text),
            target: this.data.target ?? '_self',
        });

        // Validate link element
        if (!(link instanceof HTMLAnchorElement)) {
            this.logger.warn('Failed to create an anchor element for LinkElement.');
            return null;
        }

        if (this.data.emotion) {
            this.applyEmotionAttributes(link, this.data.emotion);
        }

        this.logger.debug(`Created LinkElement with href: "${link.href}"`);
        return link;
    }
}

// List Element
class ListElement extends BaseContentElement<ListData> {
    create(): Node | null {
        const {ordered, items} = this.data;

        if (!Array.isArray(items)) {
            this.logger.warn('ListElement items are not an array.');
            return null;
        }

        const listTag = ordered ? 'ol' : 'ul';
        const list = this.createElement(listTag);

        items.forEach((item: ListItem, index: number) => {
            const listItem = this.createElement('li');

            // Type guard to determine if the item is text or a content element
            if (item.type === 'text') {
                // Handle text list item
                if (item.text === undefined || item.text === null) {
                    this.logger.warn(`ListItem at index ${index} has undefined or null text.`);
                    listItem.textContent = '';
                } else {
                    const text = typeof item.text === 'string' ? item.text : JSON.stringify(item.text);
                    listItem.textContent = text;
                }
            } else {
                // Handle content list item
                try {
                    const contentElement = this.renderer.contentFactory.createContentElement(item);
                    if (contentElement) {
                        const node = contentElement.create();
                        if (node) {
                            listItem.appendChild(node);
                        } else {
                            this.logger.warn(`Failed to create node for list item type: ${item.type} at index ${index}`);
                        }
                    } else {
                        this.logger.warn(`Unknown list item type: ${item.type} at index ${index}`);
                    }
                } catch (error) {
                    this.logger.error(`Error creating content for list item at index ${index}: ${error}`);
                }
            }

            list.appendChild(listItem);
        });

        if (this.data.emotion) {
            this.applyEmotionAttributes(list, this.data.emotion);
        }

        this.logger.debug(`Created ListElement as <${listTag}> with ${items.length} items.`);
        return list;
    }
}

// Emphasis Element
class EmphasisElement extends BaseContentElement<EmphasisData> {
    create(): Node | null {
        if (!this.data.text) {
            this.logger.warn('EmphasisElement created without text.');
            return null;
        }

        const emphasisTag = this.data.tag ?? 'em';
        const validTags = ['em', 'strong', 'u', 'mark'];

        if (!validTags.includes(emphasisTag)) {
            this.logger.warn(`EmphasisElement created with invalid tag: "${emphasisTag}". Defaulting to <em>.`);
        }

        const tagToUse = validTags.includes(emphasisTag) ? emphasisTag : 'em';
        const emphasis = this.createElement(tagToUse, {
            textContent: String(this.data.text),
        });

        // Validate emphasis element
        if (!(emphasis instanceof HTMLElement)) {
            this.logger.warn(`Failed to create an emphasis element with tag: "${tagToUse}".`);
            return null;
        }

        if (this.data.emotion) {
            this.applyEmotionAttributes(emphasis, this.data.emotion);
        }

        this.logger.debug(`Created EmphasisElement with tag: <${tagToUse}> and text: "${this.data.text}"`);
        return emphasis;
    }
}

// Rhythm Element - Uses <custom-rhythm>
class RhythmElement extends BaseContentElement<RhythmData> {
    create(): Node | null {
        if (!Array.isArray(this.data.steps)) {
            this.logger.warn('RhythmElement created without steps.');
            return null;
        }

        const rhythm = this.createElement('custom-rhythm');
        (rhythm as any).steps = this.data.steps;

        // Validate if 'steps' is set correctly
        if (!Array.isArray((rhythm as any).steps)) {
            this.logger.warn('RhythmElement steps are not set correctly.');
            return null;
        }

        // Apply emotion attributes if present
        if (this.data.emotion) {
            this.applyEmotionAttributes(rhythm, this.data.emotion);
        }

        this.logger.debug('Created RhythmElement.');
        return rhythm;
    }
}

// Custom Tooltip Element
class CustomTooltipElement extends BaseContentElement<CustomTooltipData> {
    create(): Node | null {
        if (!this.data.text) {
            this.logger.warn('CustomTooltipElement created without text.');
            return null;
        }

        const tooltip = this.createElement('custom-tooltip', {
            text: String(this.data.text),
            position: this.data.position ?? 'top',
        });

        // Append children if any
        this.appendChildren(tooltip);

        // Apply emotion attributes if present
        if (this.data.emotion) {
            this.applyEmotionAttributes(tooltip, this.data.emotion);
        }

        this.logger.debug('Created CustomTooltipElement.');
        return tooltip;
    }
}

// Custom Rating Element
class CustomRatingElement extends BaseContentElement<CustomRatingData> {
    create(): Node | null {
        const max = typeof this.data.max === 'number' ? this.data.max : 5;
        const value = typeof this.data.value === 'number' ? this.data.value : 0;
        const readonly = Boolean(this.data.readonly);

        const rating = this.createElement('custom-rating', {
            max: max,
            value: value,
            readonly: readonly,
        });

        // Validate rating element
        if (!(rating instanceof HTMLElement)) {
            this.logger.warn('Failed to create a custom-rating element.');
            return null;
        }

        // Handle rating changes if necessary
        if (!readonly) {
            rating.addEventListener('rating-changed', (e: Event) => {
                const detail = (e as CustomEvent).detail;
                this.logger.info(`Rating changed to: ${detail.value}`);
                try {
                    this.renderer.handleAction('ratingChanged', detail.value);
                } catch (error) {
                    this.logger.error(`Error handling ratingChanged action: ${error}`);
                }
            });
        }

        if (this.data.emotion) {
            this.applyEmotionAttributes(rating, this.data.emotion);
        }

        this.logger.debug('Created CustomRatingElement.');
        return rating;
    }
}

// Custom Quiz Element
class CustomQuizElement extends BaseContentElement<CustomQuizData> {
    create(): Node | null {
        if (!Array.isArray(this.data.questions)) {
            this.logger.warn('CustomQuizElement created without questions.');
            return null;
        }

        const quiz = this.createElement('custom-quiz', {
            questions: this.data.questions,
        });

        // Validate quiz element
        if (!(quiz instanceof HTMLElement)) {
            this.logger.warn('Failed to create a custom-quiz element.');
            return null;
        }

        if (this.data.onComplete) {
            quiz.addEventListener('quiz-completed', (e: Event) => {
                const detail = (e as CustomEvent).detail;
                this.logger.info(`Quiz completed with score: ${detail.score}/${detail.total}`);
                try {
                    this.renderer.handleAction(this.data.onComplete, detail);
                } catch (error) {
                    this.logger.error(`Error handling quiz completion: ${error}`);
                }
            });
        }

        // Apply emotion attributes if present
        if (this.data.emotion) {
            this.applyEmotionAttributes(quiz, this.data.emotion);
        }

        this.logger.debug('Created CustomQuizElement.');
        return quiz;
    }
}

// Custom Puzzle Element
class CustomPuzzleElement extends BaseContentElement<CustomPuzzleData> {
    create(): Node | null {
        if (!this.data.puzzleId) {
            this.logger.warn('CustomPuzzleElement created without a puzzleId.');
            return null;
        }

        const puzzle = this.createElement('custom-puzzle', {
            puzzleId: String(this.data.puzzleId),
            difficulty: this.data.difficulty ?? 'medium',
            hint: this.data.hint ?? '',
        });

        // Validate puzzle element
        if (!(puzzle instanceof HTMLElement)) {
            this.logger.warn('Failed to create a custom-puzzle element.');
            return null;
        }

        // Handle the 'puzzle-solved' event
        if (this.data.onSolve) {
            puzzle.addEventListener('puzzle-solved', (e: Event) => {
                const detail = (e as CustomEvent).detail;
                this.logger.info(`Puzzle solved: ${detail.puzzleId}`);
                try {
                    this.renderer.handleAction(this.data.onSolve, detail);
                } catch (error) {
                    this.logger.error(`Error handling puzzle-solved action: ${error}`);
                }
            });
        }

        // Recursively append children if any
        this.appendChildren(puzzle);

        // Apply emotion attributes if present
        if (this.data.emotion) {
            this.applyEmotionAttributes(puzzle, this.data.emotion);
        }

        this.logger.debug(`Created CustomPuzzleElement with ID: "${(puzzle as any).puzzleId}"`);
        return puzzle;
    }
}

// Custom Reflection Element
class CustomReflectionElement extends BaseContentElement<CustomReflectionData> {
    create(): Node | null {
        if (!this.data.reflectionId || !this.data.prompt) {
            this.logger.warn('CustomReflectionElement created without reflectionId or prompt.');
            return null;
        }

        const reflection = this.createElement('custom-reflection', {
            reflectionId: String(this.data.reflectionId),
            prompt: String(this.data.prompt),
        });

        // Validate reflection element
        if (!(reflection instanceof HTMLElement)) {
            this.logger.warn('Failed to create a custom-reflection element.');
            return null;
        }

        // Handle the 'reflection-submitted' event
        if (this.data.onSubmit) {
            reflection.addEventListener('reflection-submitted', (e: Event) => {
                const detail = (e as CustomEvent).detail;
                this.logger.info(`Reflection submitted: ${detail.reflectionId}`);
                try {
                    this.renderer.handleAction(this.data.onSubmit, detail);
                } catch (error) {
                    this.logger.error(`Error handling reflection-submitted action: ${error}`);
                }
            });
        }

        // Recursively append children if any
        this.appendChildren(reflection);

        // Apply emotion attributes if present
        if (this.data.emotion) {
            this.applyEmotionAttributes(reflection, this.data.emotion);
        }

        this.logger.debug(`Created CustomReflectionElement with ID: "${(reflection as any).reflectionId}"`);
        return reflection;
    }
}

// Custom Modal Element - Uses <custom-modal>
class CustomModalElement extends BaseContentElement<CustomModalData> {
    create(): Node | null {
        if (!this.data.modalId || !this.data.title) {
            this.logger.warn('CustomModalElement created without modalId or title.');
            return null;
        }

        const modal = this.createElement('custom-modal', {
            modalId: String(this.data.modalId),
            title: String(this.data.title),
            size: this.data.size ?? 'medium',
            isOpen: Boolean(this.data.isOpen),
        });

        // Validate modal element
        if (!(modal instanceof HTMLElement)) {
            this.logger.warn('Failed to create a custom-modal element.');
            return null;
        }

        // Handle modal close event if needed
        if (this.data.onClose) {
            modal.addEventListener('modal-closed', (e: Event) => {
                const detail = (e as CustomEvent).detail;
                this.logger.info(`Modal closed: ${detail.modalId}`);
                try {
                    this.renderer.handleAction(this.data.onClose, detail);
                } catch (error) {
                    this.logger.error(`Error handling modal-closed action: ${error}`);
                }
            });
        }

        // Recursively append children if any
        this.appendChildren(modal);

        // Apply emotion attributes if present
        if (this.data.emotion) {
            this.applyEmotionAttributes(modal, this.data.emotion);
        }

        this.logger.debug(`Created CustomModalElement with ID: "${(modal as any).modalId}"`);
        return modal;
    }
}

// Custom Carousel Element - Uses <custom-carousel>
class CustomCarouselElement extends BaseContentElement<CustomCarouselData> {
    create(): Node | null {
        if (!Array.isArray(this.data.items)) {
            this.logger.warn('CustomCarouselElement created without items.');
            return null;
        }

        const carousel = this.createElement('custom-carousel', {
            items: this.data.items,
            interval: this.data.interval ?? 3000,
            autoPlay: (this.data as any).autoPlay ?? false,
            showIndicators: this.data.showIndicators ?? true,
            showArrows: this.data.showArrows ?? true,
        });

        // Validate carousel element
        if (!(carousel instanceof HTMLElement)) {
            this.logger.warn('Failed to create a custom-carousel element.');
            return null;
        }

        // Handle carousel events if needed
        if (this.data.onItemChange) {
            carousel.addEventListener('slide-changed', (e: Event) => {
                const detail = (e as CustomEvent).detail;
                this.logger.info(`Carousel slide changed to: ${detail.currentIndex}`);
                try {
                    this.renderer.handleAction(this.data.onItemChange, detail);
                } catch (error) {
                    this.logger.error(`Error handling slide-changed action: ${error}`);
                }
            });
        }

        // Recursively append children if any (for nested content)
        this.appendChildren(carousel);

        // Apply emotion attributes if present
        if (this.data.emotion) {
            this.applyEmotionAttributes(carousel, this.data.emotion);
        }

        this.logger.debug('Created CustomCarouselElement.');
        return carousel;
    }
}

// Custom Progress Element - Uses <custom-progress>
class CustomProgressElement extends BaseContentElement<CustomProgressData> {
    create(): Node | null {
        let data: any = this.data;
        if (typeof data.value !== 'number') {
            this.logger.warn('CustomProgressElement created without a valid value.');
            return null;
        }

        const progress = this.createElement('custom-progress', {
            value: data.value,
            max: data.max ?? 100,
            color: data.color ?? '#4caf50',
            animated: Boolean(data.animated),
        });

        // Validate progress element
        if (!(progress instanceof HTMLElement)) {
            this.logger.warn('Failed to create a custom-progress element.');
            return null;
        }

        // Handle progress updates if needed
        if (data.onUpdate) {
            progress.addEventListener('progress-updated', (e: Event) => {
                const detail = (e as CustomEvent).detail;
                this.logger.info(`Progress updated to: ${detail.value}/${(progress as any).max}`);
                try {
                    this.renderer.handleAction(data.onUpdate, detail);
                } catch (error) {
                    this.logger.error(`Error handling progress-updated action: ${error}`);
                }
            });
        }

        // Handle progress completion
        if (data.onComplete) {
            progress.addEventListener('progress-completed', (e: Event) => {
                const detail = (e as CustomEvent).detail;
                this.logger.info(`Progress completed: ${detail.value}/${(progress as any).max}`);
                try {
                    this.renderer.handleAction(data.onComplete, detail);
                } catch (error) {
                    this.logger.error(`Error handling progress-completed action: ${error}`);
                }
            });
        }

        // Apply emotion attributes if present
        if (data.emotion) {
            this.applyEmotionAttributes(progress, data.emotion);
        }

        this.logger.debug('Created CustomProgressElement.');
        return progress;
    }
}

// Navigation Element
class NavigationElement extends BaseContentElement<NavigationData> {
    create(): Node | null {
        const nav = this.createElement('nav');

        // Validate if children exist
        if (this.data.children && this.data.children.length > 0) {
            this.appendChildren(nav);
        } else {
            this.logger.warn('NavigationElement created without children.');
        }

        if (this.data.emotion) {
            this.applyEmotionAttributes(nav, this.data.emotion);
        }

        this.logger.debug('Created NavigationElement.');
        return nav;
    }
}

// Section Element
class SectionElement extends BaseContentElement<SectionData> {
    create(): Node | null {
        const section = this.createElement('section');

        if (this.data.title) {
            const title = this.createElement('h2');
            title.textContent = String(this.data.title);
            section.appendChild(title);
        } else {
            this.logger.warn('SectionElement created without a title.');
        }

        if (this.data.children && this.data.children.length > 0) {
            this.appendChildren(section);
        } else {
            this.logger.warn(`SectionElement titled "${this.data.title}" has no children.`);
        }

        if (this.data.emotion) {
            this.applyEmotionAttributes(section, this.data.emotion);
        }

        this.logger.debug(`Created SectionElement with title: "${this.data.title}"`);
        return section;
    }
}

// Interactive Element
class InteractiveElement extends BaseContentElement<InteractiveElementData> {
    create(): Node | null {
        if (!this.data.interactionType || !Array.isArray(this.data.actions)) {
            this.logger.warn('InteractiveElement created without interactionType or actions.');
            return null;
        }

        const interactive = this.createElement('div', {
            className: 'interactive-element',
        });

        // Validate interactive element
        if (!(interactive instanceof HTMLElement)) {
            this.logger.warn('Failed to create an interactive-element.');
            return null;
        }

        // Handle interactions based on interactionType
        const interactionMap: Record<InteractionType, string> = {
            [InteractionType.Click]: 'click',
            [InteractionType.Hover]: 'mouseenter',
            [InteractionType.Drag]: 'drag',
            // Add more mappings as needed
        };

        const eventType = interactionMap[this.data.interactionType] || 'click';

        this.data.actions.forEach(({action, payload}) => {
            if (!action) {
                this.logger.warn('InteractiveElement has an action without an identifier.');
                return;
            }

            interactive.addEventListener(eventType, () => {
                this.logger.info(`Interactive action triggered: ${action}`);
                try {
                    this.renderer.handleAction(action, payload);
                } catch (error) {
                    this.logger.error(`Error handling interactive action "${action}": ${error}`);
                }
            });
        });

        // Append children if any
        if (this.data.children && this.data.children.length > 0) {
            this.appendChildren(interactive);
        } else {
            this.logger.warn('InteractiveElement has no children.');
        }

        // Apply emotion attributes if present
        if (this.data.emotion) {
            this.applyEmotionAttributes(interactive, this.data.emotion);
        }

        this.logger.debug(`Created InteractiveElement with interactionType: "${this.data.interactionType}"`);
        return interactive;
    }
}

/**
 * Example: Carousel Item Element
 * Ensure that this class is defined if carousel items contain nested content.
 */
class CarouselItemElement extends BaseContentElement<CarouselItemContentData> {
    create(): Node | null {
        if (!this.data.content) {
            this.logger.warn('CarouselItemElement created without content.');
            return null;
        }

        const item = this.createElement('div', {className: 'carousel-item'});

        // Handle nested content
        try {
            const contentElement = this.renderer.contentFactory.createContentElement(this.data.content);
            if (contentElement) {
                const node = contentElement.create();
                if (node) {
                    item.appendChild(node);
                } else {
                    this.logger.warn(`Failed to create node for carousel item content type: ${this.data.content.type}`);
                }
            } else {
                this.logger.warn(`Unknown carousel item content type: ${this.data.content.type}`);
            }
        } catch (error) {
            this.logger.error(`Error creating content for carousel item: ${error}`);
            return null;
        }

        // Apply emotion attributes if present
        if (this.data.emotion) {
            this.applyEmotionAttributes(item, this.data.emotion);
        }

        this.logger.debug('Created CarouselItemElement.');
        return item;
    }
}

/**
 * Factory class to create content elements based on their type.
 */
export class ContentElementFactory {
    private renderer: Renderer;

    // Registry to map content types to their respective classes
    private registry: Record<string, typeof BaseContentElement<any>> = {
        'text': TextElement,
        'paragraph': ParagraphElement,
        'dialogue': DialogueElement,
        'whisper': WhisperElement,
        'figure': FigureElement,
        'button': ButtonElement,
        'link': LinkElement,
        'list': ListElement,
        'emphasis': EmphasisElement,
        'rhythm': RhythmElement,
        'custom-tooltip': CustomTooltipElement,
        'custom-rating': CustomRatingElement,
        'custom-quiz': CustomQuizElement,
        'custom-puzzle': CustomPuzzleElement,
        'custom-reflection': CustomReflectionElement,
        'custom-modal': CustomModalElement,
        'custom-carousel': CustomCarouselElement,
        'custom-progress': CustomProgressElement,
        'navigation': NavigationElement,
        'section': SectionElement,
        'interactive-element': InteractiveElement,
        'carousel-item': CarouselItemElement, // Ensure CarouselItemElement is defined
        // Add additional content types here...
    };

    constructor(renderer: Renderer) {
        this.renderer = renderer;
    }

    /**
     * Creates a content element instance based on the provided data.
     * Utilizes a registry for scalability.
     * @param data - The content data object.
     * @returns An instance of a subclass of BaseContentElement or null if type is unknown.
     */
    createContentElement(data: ContentData): BaseContentElement<any> | null {
        const typeKey = data.type.toLowerCase();
        const ElementClass = this.registry[typeKey];

        if (!ElementClass) {
            this.renderer.logger.warn(`Unknown content element type: ${data.type}`);
            return null;
        }

        try {
            // @ts-ignore
            return new ElementClass(data, this.renderer);
        } catch (error) {
            this.renderer.logger.error(`Error creating content element of type "${data.type}": ${error}`);
            return null;
        }
    }
}
