// === src/data/core.ts ===
// Enhanced Chapter Data Generation for Lore.Land

import {
    ChapterData,
    Section,
    SubSection,
    TextContent,
    EmotionKey,
    ContentData,
    DialogueData,
    ListData,
    ListItem,
    InteractiveElementData,
    InteractionType,
    Emotion,
    ButtonData,
    LinkData,
    CustomQuizData,
    CustomModalData,
    CustomCarouselData,
    CustomProgressData,
    CarouselItemContentData, EmphasisData, WhisperData, TextData
} from "./types";
import {Logger} from "../app/logger";

/**
 * EmotionDescriptors: Defines possible emotional states for text elements.
 */
const EmotionDescriptors: Record<EmotionKey, Emotion> = {
    [EmotionKey.Doubt]: {valence: 3, arousal: 4, discrete: ["doubt", "uncertainty"]},
    [EmotionKey.Mystery]: {valence: 5, arousal: 6, discrete: ["mystery", "enigmatic"]},
    [EmotionKey.Realization]: {valence: 7, arousal: 5, discrete: ["realization", "insight"]},
    [EmotionKey.Strength]: {valence: 6, arousal: 7, discrete: ["strength", "resilience"]},
    [EmotionKey.Unity]: {valence: 8, arousal: 5, discrete: ["unity", "harmony"]},
    [EmotionKey.Wisdom]: {valence: 7, arousal: 4, discrete: ["wisdom", "knowledge"]},
    [EmotionKey.Wonder]: {valence: 8, arousal: 6, discrete: ["wonder", "awe"]},
    [EmotionKey.Calm]: {valence: 5, arousal: 3, discrete: ["calm", "peaceful"]},
    [EmotionKey.Magical]: {valence: 6, arousal: 4, discrete: ["magical"]},
    [EmotionKey.Hope]: {valence: 7, arousal: 5, discrete: ["hope", "creation"]},
    [EmotionKey.Inspiration]: {valence: 8, arousal: 6, discrete: ["inspiration"]},
    [EmotionKey.Energy]: {valence: 7, arousal: 5, discrete: ["energy"]},
    [EmotionKey.Joy]: {valence: 9, arousal: 7, discrete: ["joy", "happiness"]},
    [EmotionKey.Sadness]: {valence: 2, arousal: 3, discrete: ["sadness", "melancholy"]},
    [EmotionKey.Anger]: {valence: 1, arousal: 8, discrete: ["anger", "frustration"]},
    [EmotionKey.Surprise]: {valence: 6, arousal: 7, discrete: ["surprise", "astonishment"]},
    [EmotionKey.Fear]: {valence: 2, arousal: 7, discrete: ["fear", "anxiety"]},
    [EmotionKey.Disgust]: {valence: 1, arousal: 5, discrete: ["disgust", "revulsion"]},
    [EmotionKey.Love]: {valence: 9, arousal: 6, discrete: ["love", "affection"]}
    // Add more emotions as needed
};

/**
 * Logger instance for the core module.
 */
const logger = new Logger('Core');

/**
 * Helper Functions to Create Content Elements
 */
namespace ContentHelpers {
    /**
     * Safely retrieves an Emotion object based on the provided EmotionKey.
     * Defaults to a neutral emotion if the key is not found.
     * @param emotionKey - The key representing the desired emotion.
     * @returns An Emotion object.
     */
    export function getEmotion(emotionKey?: EmotionKey): Emotion {
        if (emotionKey && EmotionDescriptors[emotionKey]) {
            return EmotionDescriptors[emotionKey];
        }
        logger.warn(`Emotion key "${emotionKey}" not found. Using default neutral emotion.`);
        return {valence: 5, arousal: 3, discrete: []};
    }

    /**
     * Creates a rhythm section with given steps.
     * @param steps - Array of rhythmic lines.
     * @returns A Section object containing rhythm content.
     */
    export function createRhythm(steps: string[]): Section {
        return {
            type: 'section',
            title: "Rhythm Section",
            children: [
                {
                    type: 'rhythm',
                    steps: steps
                }
            ]
        };
    }

    /**
     * Creates a paragraph subsection with given text contents.
     * @param texts - Array of TextContent objects.
     * @param emotionKey - Optional EmotionKey for the paragraph.
     * @returns A SubSection object containing paragraph content.
     */
    export function createParagraph(texts: (TextContent | EmphasisData)[], emotionKey?: EmotionKey): SubSection {
        const paragraph: SubSection = {
            type: 'paragraph',
            children: texts
        };
        if (emotionKey) {
            paragraph.emotion = getEmotion(emotionKey);
        }
        return paragraph;
    }

    /**
     * Creates a whisper subsection with given text contents.
     * @param texts - Array of TextContent objects.
     * @param emotionKey - Optional EmotionKey for the whisper.
     * @returns A SubSection object containing whisper content.
     */
    export function createWhisper(texts: TextData[], emotionKey?: EmotionKey): SubSection {
        const whisper: WhisperData = {
            type: 'whisper',
            text: texts
        };
        if (emotionKey) {
            whisper.emotion = getEmotion(emotionKey);
        }
        return whisper;
    }

    /**
     * Creates a figure subsection with provided details.
     * @param title - Optional title of the figure.
     * @param src - Image source URL.
     * @param alt - Alternative text for the image.
     * @param caption - Optional caption for the figure.
     * @param emotionKey - Optional EmotionKey for the figure.
     * @returns A SubSection object containing figure content.
     */
    export function createFigure(
        title: string,
        src: string,
        alt: string,
        caption: string,
        emotionKey?: EmotionKey
    ): SubSection {
        const figure: SubSection = {
            type: 'figure',
            title: title,
            src: src,
            alt: alt,
            loading: "lazy",
            caption: caption
        };
        if (emotionKey) {
            figure.emotion = getEmotion(emotionKey);
        }
        return figure;
    }

    /**
     * Creates an emphasized text content with a specific emotion.
     * @param text - The text to emphasize.
     * @param emotionKey - The emotion key from EmotionKey enum.
     * @returns A TextContent object representing emphasized text.
     */
    export function createEmphasizedText(text: string, emotionKey: EmotionKey): EmphasisData {
        return {
            type: 'emphasis',
            tag: 'strong',
            text: text,
            emotion: getEmotion(emotionKey)
        };
    }

    /**
     * Creates a normal text content.
     * @param text - The text content.
     * @returns A TextContent object representing normal text.
     */
    export function createNormalText(text: string): TextContent {
        return {
            type: 'text',
            text: text
        };
    }

    /**
     * Creates a dialogue content block.
     * @param speaker - Name of the speaker.
     * @param lines - Array of dialogue lines.
     * @param emotionKey - Optional emotion key.
     * @param avatar - Optional avatar URL.
     * @returns A DialogueData object representing dialogue content.
     */
    export function createDialogue(
        speaker: string,
        lines: string[] | { text: string; action: () => void }[],
        emotionKey?: EmotionKey,
        avatar?: string
    ): DialogueData {
        const dialogue: DialogueData = {
            type: 'dialogue',
            speaker: speaker,
            lines: lines.map(line => typeof line === 'string' ? {
                text: line, action() {
                    console.log('hello')
                }
            } : line)
        };
        if (emotionKey) {
            dialogue.emotion = getEmotion(emotionKey);
        }
        if (avatar) {
            dialogue.avatar = avatar;
        }
        return dialogue;
    }

    /**
     * Creates a list content block.
     * @param ordered - Whether the list is ordered.
     * @param items - Array of ListItem objects (text or content elements).
     * @param emotionKey - Optional EmotionKey for the list.
     * @returns A ListData object representing list content.
     */
    export function createList(
        ordered: boolean,
        items: (ListItem | string)[],
        emotionKey?: EmotionKey
    ): ListData {
        const listItems: ListItem[] = items.map(item => {
            if (typeof item === 'string') {
                return {
                    type: 'text',
                    text: item
                };
            } else {
                return item;
            }
        });
        const list: ListData = {
            type: 'list',
            ordered: ordered,
            items: listItems
        };
        if (emotionKey) {
            list.emotion = getEmotion(emotionKey);
        }
        return list;
    }

    /**
     * Creates an interactive element.
     * @param interactionType - Type of interaction.
     * @param actions - Array of action identifiers.
     * @param emotionKey - Optional EmotionKey for the interactive element.
     * @returns An InteractiveElementData object representing an interactive element.
     */
    export function createInteractiveElement(
        interactionType: InteractionType,
        actions: string[],
        emotionKey?: EmotionKey
    ): InteractiveElementData {
        return {
            type: 'interactive-element',
            interactionType: interactionType,
            actions: actions.map(action => ({action})),
            emotion: emotionKey ? getEmotion(emotionKey) : undefined
        };
    }

    /**
     * Creates a button element.
     * @param text - The button text.
     * @param action - Action identifier for the button click.
     * @param payload - Optional payload for the action.
     * @param emotionKey - Optional EmotionKey for the button.
     * @returns A ButtonData object representing a button.
     */
    export function createButton(
        text: string,
        action: string,
        payload?: any,
        emotionKey?: EmotionKey
    ): ButtonData {
        const button: ButtonData = {
            type: 'button',
            text: text,
            action: action,
            payload: payload
        };
        if (emotionKey) {
            button.emotion = getEmotion(emotionKey);
        }
        return button;
    }

    /**
     * Creates a link element.
     * @param text - The link text.
     * @param href - The URL the link points to.
     * @param target - Optional target attribute.
     * @param emotionKey - Optional EmotionKey for the link.
     * @returns A LinkData object representing a link.
     */
    export function createLink(
        text: string,
        href: string,
        target?: '_blank' | '_self' | '_parent' | '_top',
        emotionKey?: EmotionKey
    ): LinkData {
        const link: LinkData = {
            type: 'link',
            href: href,
            text: text,
            target: target
        };
        if (emotionKey) {
            link.emotion = getEmotion(emotionKey);
        }
        return link;
    }

    /**
     * Creates a custom modal element.
     * @param modalId - Unique identifier for the modal.
     * @param title - Title of the modal.
     * @param content - HTML content of the modal.
     * @param emotionKey - Optional EmotionKey for the modal.
     * @returns A CustomModalData object representing a modal.
     */
    export function createCustomModal(
        modalId: string,
        title: string,
        content: string,
        emotionKey?: EmotionKey
    ): CustomModalData {
        return {
            type: 'custom-modal',
            modalId: modalId,
            title: title,
            size: 'medium',
            isOpen: false,
            onClose: "closeModal",
            children: [
                createParagraph([
                    createEmphasizedText(content, EmotionKey.Inspiration)
                ]),
                createButton('Close', 'closeModal', {modalId: modalId}, EmotionKey.Calm)
            ],
            emotion: emotionKey ? getEmotion(emotionKey) : undefined
        };
    }

    /**
     * Creates a custom carousel element.
     * @param items - Array of image URLs or nested content.
     * @param interval - Auto-slide interval in milliseconds.
     * @param emotionKey - Optional EmotionKey for the carousel.
     * @returns A CustomCarouselData object representing a carousel.
     */
    export function createCustomCarousel(
        items: Array<string | CarouselItemContentData>,
        interval: number = 3000,
        emotionKey?: EmotionKey
    ): CustomCarouselData {
        const carouselItems: Array<string | CarouselItemContentData> = items.map(item => {
            if (typeof item === 'string') {
                return item; // Image URL
            } else {
                return item; // CarouselItemContentData
            }
        });

        return {
            type: 'custom-carousel',
            items: carouselItems,
            interval: interval,
            showIndicators: true,
            showArrows: true,
            onItemChange: "handleCarouselItemChange",
            emotion: emotionKey ? getEmotion(emotionKey) : undefined
        };
    }

    /**
     * Creates a custom progress element.
     * @param value - Current progress value.
     * @param max - Maximum progress value.
     * @param color - Color of the progress bar.
     * @param emotionKey - Optional EmotionKey for the progress bar.
     * @returns A CustomProgressData object representing a progress bar.
     */
    export function createCustomProgress(
        value: number,
        max: number = 100,
        color: string = '#2196F3',
        emotionKey?: EmotionKey
    ): CustomProgressData {
        const progress: CustomProgressData = {
            type: 'custom-progress',
            value: value,
            max: max,
            color: color,
            animated: true,
            onComplete: "handleProgressComplete"
        };
        if (emotionKey) {
            progress.emotion = getEmotion(emotionKey);
        }
        return progress;
    }
}

/**
 * Core: Generates the core chapter data for Lore.Land.
 * @param chapter - The chapter number to generate data for.
 * @returns A ChapterData object representing the chapter's content.
 */
export function Core(chapter: number = 1): ChapterData {
    logger.info(`Generating data for chapter ${chapter}`);

    return {
        chapterNumber: chapter,
        title: "Lore.Land's Core Symphony: A Poetic Integration",
        description: "An immersive journey through the mystical world of Lore.Land, where symbols guide the protagonist's path.",
        mood: "Enchanted, Mystical",
        period: "Twilight",
        tags: ["Introduction", "Symbols", "Magic", "Technology"],
        themes: ["Creation", "Growth", "Integration", "Discovery"],
        sections: [
            // Rhythm Section
            ContentHelpers.createRhythm([
                "This is the core, whispers the twilight breeze,",
                "Remain true, echoes through enchanted trees.",
                "I love you, murmurs the moonlit night,",
                "Guiding the journey with gentle light."
            ]),

            // Introduction Section
            {
                type: 'section',
                title: "Introduction: The Familiar Dawn",
                children: [
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("In the heart of "),
                        ContentHelpers.createEmphasizedText("Lore.Land", EmotionKey.Magical),
                        ContentHelpers.createNormalText(", where dreams reside, our protagonist awakens, eyes open wide. In their familiar space, comfort and calm, unaware of the symbols weaving a charm.")
                    ]),
                    ContentHelpers.createWhisper([
                        ContentHelpers.createNormalText("Things are not unusual here, begins the tale, a temporary peace where hopes set sail.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Yet shadows loom where light once danced, whispering secrets of symbols enhanced.")
                    ]),
                    // Added depth: More description and emotions
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("The soft glow of dawn filters through the curtains, painting patterns on the floor. A sense of "),
                        ContentHelpers.createEmphasizedText("anticipation", EmotionKey.Hope),
                        ContentHelpers.createNormalText(" stirs within, though the source remains unknown.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Outside, the world of "),
                        ContentHelpers.createEmphasizedText("Lore.Land", EmotionKey.Magical),
                        ContentHelpers.createNormalText(" awakens, the air thick with untapped potential. The scent of "),
                        ContentHelpers.createEmphasizedText("possibility", EmotionKey.Hope),
                        ContentHelpers.createNormalText(" fills the air, as if the universe holds its breath.")
                    ])
                ]
            },

            // Dialogue with Dr. Bonehonk
            {
                type: 'section',
                title: "A Conversation with Dr. Bonehonk",
                children: [
                    ContentHelpers.createDialogue(
                        "Dr. Bonehonk",
                        [
                            "Welcome to Lore.Land, where every symbol tells a story.",
                            "With Model 17.04, we bridge the gap between magic and technology.",
                            "These symbols are more than mere marks—they are keys to understanding our world."
                        ],
                        EmotionKey.Inspiration,
                        "/static/2024-11-B/01.png"
                    ),
                    ContentHelpers.createDialogue(
                        "Protagonist",
                        [
                            "It's incredible! How do these symbols interact with our world?",
                            "I can't wait to explore and uncover their secrets.",
                            "What role do I play in all of this?"
                        ],
                        EmotionKey.Joy
                    ),
                    ContentHelpers.createDialogue(
                        "Dr. Bonehonk",
                        [
                            "Your journey will intertwine with the symbols. As you understand them, they will reveal paths only you can traverse.",
                            "Remember, each symbol resonates with a part of you. Embrace them, and they'll guide you."
                        ],
                        EmotionKey.Wisdom
                    ),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("As the conversation unfolds, the protagonist feels a surge of "),
                        ContentHelpers.createEmphasizedText("Inspiration", EmotionKey.Inspiration),
                        ContentHelpers.createNormalText(", ready to embark on the adventure that awaits.")
                    ])
                ]
            },

            // The Whispering Woods Section
            {
                type: 'section',
                title: "The Whispering Woods",
                children: [
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Venturing into the "),
                        ContentHelpers.createEmphasizedText("Whispering Woods", EmotionKey.Magical),
                        ContentHelpers.createNormalText(", the protagonist is enveloped by a symphony of rustling leaves and distant melodies.")
                    ]),
                    ContentHelpers.createWhisper([
                        ContentHelpers.createNormalText("Listen... can you hear the secrets in the wind?")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("The symbols begin to appear subtly among the trees—etched into bark, formed by intertwining branches, and even dancing in the patterns of light and shadow.")
                    ]),
                    ContentHelpers.createFigure(
                        "Symbols in Nature",
                        "/static/2024-11-B/lore_portal.png",
                        "Symbols hidden in the forest",
                        "The forest holds many secrets, its very essence intertwined with the symbols of Lore.Land."
                    ),
                    ContentHelpers.createDialogue(
                        "Mysterious Voice",
                        [
                            "The symbols you seek are part of everything here.",
                            "Trust in them, and they will reveal the path."
                        ],
                        EmotionKey.Mystery
                    ),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("A sense of "),
                        ContentHelpers.createEmphasizedText("Wonder", EmotionKey.Wonder),
                        ContentHelpers.createNormalText(" fills the protagonist. The journey is no longer just about discovery, but about connection.")
                    ])
                ]
            },

            // Emerging Development: Threads of Insight
            {
                type: 'section',
                title: "Emerging Development: Threads of Insight",
                children: [
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("As days unfold in rhythmic flow, the protagonist begins to grow.")
                    ]),
                    ContentHelpers.createRhythm([
                        "Uncovering bonds, relationships deep, understanding the symbols they keep."
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Oscillations between large and small, macroscopic views and details that enthrall.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Reasons to explore, to seek, to find, growth intertwined with changing mind.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createEmphasizedText("Perspective `@`", EmotionKey.Energy),
                        ContentHelpers.createNormalText(" altering the paradigm. The environment's call and voices near, shape philosophies held dear.")
                    ]),
                    // Added depth: Internal conflict and realization
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Yet, with each revelation comes a "),
                        ContentHelpers.createEmphasizedText("challenge", EmotionKey.Fear),
                        ContentHelpers.createNormalText(". The symbols sometimes blur, their meanings elusive, leading to moments of doubt.")
                    ]),
                    ContentHelpers.createDialogue(
                        "Inner Voice",
                        [
                            "Am I truly understanding, or merely scratching the surface?",
                            "What if the symbols lead me astray?"
                        ],
                        EmotionKey.Doubt
                    ),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Through reflection, the protagonist recognizes that doubt is part of growth. Embracing uncertainty becomes a new "),
                        ContentHelpers.createEmphasizedText("strength", EmotionKey.Strength),
                        ContentHelpers.createNormalText(".")
                    ])
                ]
            },

            // First Challenge: The Labyrinth of Thoughts
            {
                type: 'section',
                title: "First Challenge: The Labyrinth of Thoughts",
                children: [
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("The path leads to a vast labyrinth, its walls adorned with shifting symbols.")
                    ]),
                    ContentHelpers.createFigure(
                        "The Labyrinth",
                        "/static/2024-11-B/labyrinth.png",
                        "An intricate labyrinth with moving walls",
                        "The Labyrinth of Thoughts tests one's understanding of the symbols and oneself."
                    ),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("An inscription reads: "),
                        ContentHelpers.createEmphasizedText("\"Only by aligning the symbols within can one find the true path forward.\"", EmotionKey.Mystery)
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("This is the first true test of the protagonist's connection with the symbols.")
                    ]),
                    // Interactive Puzzle
                    ContentHelpers.createInteractiveElement(
                        InteractionType.Click,
                        ["openPuzzleModal"],
                        EmotionKey.Inspiration
                    ),
                    {
                        type: 'custom-puzzle',
                        puzzleId: "labyrinthPuzzle",
                        difficulty: 'medium',
                        hint: "Consider how the symbols relate to your journey so far.",
                        onSolve: "unlockLabyrinthDoor",
                        children: [
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("Solve the puzzle by aligning the symbols correctly.")
                            ])
                        ],
                        emotion: ContentHelpers.getEmotion(EmotionKey.Realization)
                    },
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Upon solving the puzzle, the labyrinth shifts, opening a path ahead.")
                    ]),
                    ContentHelpers.createDialogue(
                        "Protagonist",
                        [
                            "I see now—it's not just about knowing the symbols, but feeling them.",
                            "They are reflections of my own journey."
                        ],
                        EmotionKey.Realization
                    )
                ]
            },

            // Lore: Deeper Connections
            {
                type: 'section',
                title: "Lore: Deeper Connections",
                children: [
                    // The Echo of Forgotten Modes of Expression
                    {
                        type: 'section',
                        title: "The Echo of Forgotten Modes of Expression",
                        children: [
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("Soft signals from shrouded shores, the hushed hum of histories half-heard,")
                            ]),
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("Rippling through the sands of time, beckoning the brave to bend their ear.")
                            ]),
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("These fleeting flows, faintly forgotten, shape the untrodden trails,")
                            ]),
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("Twisting through the thickets of tomorrow's tales.")
                            ]),
                            // Added depth: Connection to protagonist
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("The protagonist feels these echoes, a resonance with the symbols and stories of old.")
                            ])
                        ]
                    },

                    // The Loom of Fate’s Threads
                    {
                        type: 'section',
                        title: "The Loom of Fate’s Threads",
                        children: [
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("A silken web, unseen, unfurling, in the murmur of cosmic breath,")
                            ]),
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("Where every thread sways, a shimmering flicker in the fog of might-be’s.")
                            ]),
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("Each strand hums with half-formed choices, caught between the dance of distant stars,")
                            ]),
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("And the echo of hearts yet to beat, tugging at the edges of what is, and what could be,")
                            ]),
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("In a rhythm no one can quite remember.")
                            ]),
                            // Added depth: Choices and consequences
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("The protagonist contemplates their choices, each one a thread in the grand tapestry.")
                            ])
                        ]
                    },

                    // The Prism of Infinitely Reflective Pi(e)
                    {
                        type: 'section',
                        title: "The Prism of Infinitely Reflective Pi(e)",
                        children: [
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("A slice of the infinite, spun from the fabric of wonder,")
                            ]),
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("Its surface fractured like a thousand forgotten flavors of pie,")
                            ]),
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("Each bite a new taste of reality, sweeter and stranger than the last.")
                            ]),
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("With every glance, a new world spills forth—")
                            ]),
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("Tangy, tender, and twisted, whirling the beholder in a dance of endless possibilities,")
                            ]),
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("Questioning which piece of the pie is truly theirs to claim.")
                            ]),
                            // Added depth: Personal reflection
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("Staring into the prism, the protagonist sees reflections of their own potential.")
                            ])
                        ]
                    }
                ]
            },

            // Core Symbols of Lore.Land
            {
                type: 'section',
                title: "Core Symbols of Lore.Land",
                children: [
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Lore.Land is governed by a set of core symbols, each holding unique powers and meanings:")
                    ]),
                    ContentHelpers.createList(false, [
                        "Potential `~` - Represents limitless possibilities and creation.",
                        "Vibration `#` - Symbolizes dynamic energy and change.",
                        "Ground `.` - Anchors and provides stability.",
                        "Wonder `?` - Ignites curiosity and exploration.",
                        "Action `!` - Drives decisive actions and bold moves.",
                        "Value `*` - Highlights true worth and significance.",
                        "Subject `&` - Binds stories and narratives together.",
                        "Perspective `@` - Alters viewpoints and understanding.",
                        "Concept `<>` - Frames and shapes thoughts.",
                        "Mode `[]` - Adapts to varying conditions.",
                        "Definition `{}` - Holds and defines truths.",
                        "Integration `^` - Connects all elements harmoniously."
                    ], EmotionKey.Energy),
                    // Added depth: Interactive exploration
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Choose a symbol to explore its deeper meaning:")
                    ]),
                    ContentHelpers.createInteractiveElement(InteractionType.Click, [
                        "openSymbolDetails",
                        "logSymbolSelection"
                    ], EmotionKey.Inspiration)
                ]
            },

            // Interactive Symbol Exploration
            {
                type: 'section',
                title: "Interactive Symbol Exploration",
                children: [
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Click on any symbol below to delve deeper into its significance and powers:")
                    ]),
                    // Interactive buttons for each symbol
                    ContentHelpers.createList(false, [
                        ContentHelpers.createButton("Potential `~`", "exploreSymbolPotential", undefined, EmotionKey.Hope),
                        ContentHelpers.createButton("Vibration `#`", "exploreSymbolVibration", undefined, EmotionKey.Energy),
                        ContentHelpers.createButton("Ground `.`", "exploreSymbolGround", undefined, EmotionKey.Calm),
                        ContentHelpers.createButton("Wonder `?`", "exploreSymbolWonder", undefined, EmotionKey.Hope),
                        ContentHelpers.createButton("Action `!`", "exploreSymbolAction", undefined, EmotionKey.Energy),
                        ContentHelpers.createButton("Value `*`", "exploreSymbolValue", undefined, EmotionKey.Hope),
                        ContentHelpers.createButton("Subject `&`", "exploreSymbolSubject", undefined, EmotionKey.Energy),
                        ContentHelpers.createButton("Perspective `@`", "exploreSymbolPerspective", undefined, EmotionKey.Energy),
                        ContentHelpers.createButton("Concept `<>`", "exploreSymbolConcept", undefined, EmotionKey.Hope),
                        ContentHelpers.createButton("Mode `[]`", "exploreSymbolMode", undefined, EmotionKey.Energy),
                        ContentHelpers.createButton("Definition `{}`", "exploreSymbolDefinition", undefined, EmotionKey.Hope),
                        ContentHelpers.createButton("Integration `^`", "exploreSymbolIntegration", undefined, EmotionKey.Hope)
                    ], EmotionKey.Energy)
                ]
            },

            // The Journey's Pulse: Integration of Symbols
            {
                type: 'section',
                title: "The Journey's Pulse: Integration of Symbols",
                children: [
                    ContentHelpers.createParagraph([
                        ContentHelpers.createEmphasizedText("Potential `~`", EmotionKey.Hope),
                        ContentHelpers.createNormalText(" sways with dreams so high, "),
                        ContentHelpers.createEmphasizedText("Vibration `#`", EmotionKey.Energy),
                        ContentHelpers.createNormalText(" pulses beneath the sky. "),
                        ContentHelpers.createEmphasizedText("Ground `.`", EmotionKey.Calm),
                        ContentHelpers.createNormalText(" anchors every step we take, "),
                        ContentHelpers.createEmphasizedText("Wonder `?`", EmotionKey.Hope),
                        ContentHelpers.createNormalText(" ignites the paths we make.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createEmphasizedText("Action `!`", EmotionKey.Energy),
                        ContentHelpers.createNormalText(" drives the boldest deeds, "),
                        ContentHelpers.createEmphasizedText("Value `*`", EmotionKey.Hope),
                        ContentHelpers.createNormalText(" highlights where true worth leads. "),
                        ContentHelpers.createEmphasizedText("Subject `&`", EmotionKey.Energy),
                        ContentHelpers.createNormalText(" binds our stories tight, "),
                        ContentHelpers.createEmphasizedText("Perspective `@`", EmotionKey.Energy),
                        ContentHelpers.createNormalText(" shifts our sight.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createEmphasizedText("Concept `<>`", EmotionKey.Hope),
                        ContentHelpers.createNormalText(" frames the thoughts we spin, "),
                        ContentHelpers.createEmphasizedText("Mode `[]`", EmotionKey.Energy),
                        ContentHelpers.createNormalText(" adapts to every tide, "),
                        ContentHelpers.createEmphasizedText("Definition `{}`", EmotionKey.Hope),
                        ContentHelpers.createNormalText(" holds the truths inside."),
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createEmphasizedText("Integration `^`", EmotionKey.Hope),
                        ContentHelpers.createNormalText(" weaves all parts as one, connecting realms beneath the sun. Through symbols bright and letters grand, "),
                        ContentHelpers.createEmphasizedText("Lore.Land", EmotionKey.Magical),
                        ContentHelpers.createNormalText(" thrives by their command.")
                    ]),
                    // Added depth: Personal commitment
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("The protagonist feels the "),
                        ContentHelpers.createEmphasizedText("Integration `^`", EmotionKey.Unity),
                        ContentHelpers.createNormalText(" within, a harmony of all they've learned.")
                    ])
                ]
            },

            // Conclusion: The Symphony Unfolds
            {
                type: 'section',
                title: "Conclusion: The Symphony Unfolds",
                children: [
                    ContentHelpers.createRhythm([
                        "This is the core, the heart's true song,",
                        "Remain true, where we belong.",
                        "I love you, the echoes say,",
                        "Guiding us through night and day."
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("In "),
                        ContentHelpers.createEmphasizedText("Lore.Land", EmotionKey.Magical),
                        ContentHelpers.createNormalText(", where magic flows, every symbol’s power grows. From "),
                        ContentHelpers.createEmphasizedText("Wonder `?`", EmotionKey.Hope),
                        ContentHelpers.createNormalText(" to "),
                        ContentHelpers.createEmphasizedText("Action `!`", EmotionKey.Energy),
                        ContentHelpers.createNormalText(", we forge ahead, spirits alight.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Join the symphony, embrace the lore, unlock the secrets at the core. Let rhythmic whispers be your guide, in "),
                        ContentHelpers.createEmphasizedText("Lore.Land", EmotionKey.Magical),
                        ContentHelpers.createNormalText(", where dreams abide.")
                    ]),
                    // Added depth: Call to action
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("The journey doesn't end here. It is but a new beginning. The protagonist steps forward, ready to face whatever comes next.")
                    ]),
                    // Custom Modal: Introduction to Symbols
                    ContentHelpers.createCustomModal(
                        "symbolIntroModal",
                        "Discover Symbols",
                        `
                            <p>Symbols in Lore.Land are the keys to unlocking your journey. Each symbol holds unique powers and meanings that will aid you along the way.</p>
                            <button type="button" onclick="closeModal({ modalId: 'symbolIntroModal' })">Close</button>
                        `,
                        EmotionKey.Inspiration
                    ),
                    // Custom Carousel: Symbol Gallery
                    ContentHelpers.createCustomCarousel([
                        {
                            type: 'carousel-item',
                            content: {
                                type: 'figure',
                                title: "Potential `~`",
                                src: "/static/2024-11-B/symbol_potential.png",
                                alt: "Potential symbol representation",
                                caption: "Potential `~` symbolizes limitless possibilities and creation."
                            }
                        },
                        {
                            type: 'carousel-item',
                            content: {
                                type: 'figure',
                                title: "Vibration `#`",
                                src: "/static/2024-11-B/symbol_vibration.png",
                                alt: "Vibration symbol representation",
                                caption: "Vibration `#` symbolizes dynamic energy and change."
                            }
                        },
                        // Add more carousel items as needed
                    ], 5000, EmotionKey.Wonder),
                    // Custom Progress: Chapter Progress
                    ContentHelpers.createCustomProgress(25, 100, '#2196F3', EmotionKey.Energy)
                ]
            },

            // Reflection: The Dance of Symbols and Sound
            {
                type: 'section',
                title: "Reflection: The Dance of Symbols and Sound",
                children: [
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("In Lore.Land, where symbols play,")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Letters guide the night and day.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Each character a story's part,")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Crafting magic from the heart.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("From `~` to `^`, they intertwine,")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Weaving tales both bold and fine.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("From `~` to `^`, they intertwine,")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Weaving tales both bold and fine.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("From `~` to `^`, they intertwine,")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Weaving tales both bold and fine.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("From `~` to `^`, they intertwine,")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Weaving tales both bold and fine.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("From `~` to `^`, they intertwine,")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Weaving tales both bold and fine.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("From `~` to `^`, they intertwine,")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Weaving tales both bold and fine.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("From `~` to `^`, they intertwine,")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Weaving tales both bold and fine.")
                    ]),
                    // Added depth: Personal insight
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("The protagonist realizes that they are not just part of the story—they are the storyteller.")
                    ])
                ]
            },

            // Join the Symphony
            {
                type: 'section',
                title: "Join the Symphony",
                children: [
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Embrace the symbols, feel the flow,")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Let Lore.Land's rhythms grow.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("With every mark and every line,")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Discover realms where stars align.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Are you ready to harness the power of symbols and letters, and let Lore.Land's symphony guide your journey? Step into the story and ignite your own adventure.")
                    ]),
                    // Interactive Reflection
                    {
                        type: 'custom-reflection',
                        reflectionId: "journeyReflection",
                        prompt: "What symbol resonates with you the most, and why?",
                        onSubmit: "saveUserReflection",
                        children: [
                            ContentHelpers.createParagraph([
                                ContentHelpers.createNormalText("Share your thoughts below:")
                            ])
                        ],
                        emotion: ContentHelpers.getEmotion(EmotionKey.Inspiration)
                    }
                ]
            },

            // Final Blessing
            {
                type: 'section',
                title: "Final Blessing",
                children: [
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("May the symbols of Lore.Land illuminate your path, as you traverse its poetic and magical realms.")
                    ]),
                    ContentHelpers.createParagraph([
                        ContentHelpers.createNormalText("Your mastery of these symbols unlocks new doors, rewards await for the practiced and sure. Let each symbol be a key, to the wonders you're destined to see.")
                    ]),
                    // Added depth: Farewell from Dr. Bonehonk
                    ContentHelpers.createDialogue(
                        "Dr. Bonehonk",
                        [
                            "Remember, the journey is as much within as it is without.",
                            "May your path be ever illuminated by the wisdom of the symbols."
                        ],
                        EmotionKey.Wisdom
                    )
                ]
            },

            // Interactive Quiz: Test Your Knowledge
            {
                type: 'section',
                title: "Interactive Quiz: Test Your Knowledge",
                children: [
                    {
                        type: 'custom-quiz',
                        questions: [
                            {
                                question: "What does the symbol `~` represent in Lore.Land?",
                                options: [
                                    "Dynamic Energy",
                                    "Limitless Possibilities",
                                    "Curiosity and Exploration",
                                    "True Worth"
                                ],
                                correctIndex: 1
                            },
                            {
                                question: "Which symbol anchors every step in Lore.Land?",
                                options: [
                                    "`!`",
                                    "`?`",
                                    "`.`",
                                    "`^`"
                                ],
                                correctIndex: 2
                            },
                            {
                                question: "What is the essence that connects all symbols?",
                                options: [
                                    "`#`",
                                    "`&`",
                                    "`^`",
                                    "`<>`"
                                ],
                                correctIndex: 2
                            },
                            // Added depth: Additional questions
                            {
                                question: "Which symbol ignites curiosity and exploration?",
                                options: [
                                    "`~`",
                                    "`?`",
                                    "`*`",
                                    "`&`"
                                ],
                                correctIndex: 1
                            },
                            {
                                question: "The symbol `@` in Lore.Land is associated with:",
                                options: [
                                    "Perspective",
                                    "Action",
                                    "Value",
                                    "Integration"
                                ],
                                correctIndex: 0
                            }
                        ],
                        onComplete: "showQuizResults",
                        emotion: ContentHelpers.getEmotion(EmotionKey.Inspiration)
                    }
                ]
            }
        ],
        lore: {
            loreItems: [
                {description: "Symbols in Lore.Land hold profound meanings and guide the inhabitants through their journeys."},
                {description: "Dr. Bonehonk's Model 17.04 represents the fusion of magic and technology within Lore.Land."},
                {description: "The Prism of Infinitely Reflective Pi(e) reveals endless possibilities and realities."},
                {description: "Integration `^` is the essence that connects all symbols, ensuring harmony within Lore.Land."},
                {description: "Echoes of forgotten expressions shape the untrodden paths of Lore.Land's history."},
                // Added depth: New lore items
                {description: "The Labyrinth of Thoughts challenges one's understanding of the symbols and oneself."},
                {description: "The Whispering Woods are alive with the secrets of Lore.Land, accessible to those who listen."},
                {description: "Custom Carousel showcases the beauty and power of each symbol in visual form."},
                {description: "Custom Progress tracks the protagonist's journey and mastery over the symbols."}
            ]
        },
        previousChapter: null,
        nextChapter: "Chapter 2: The Symbols Awaken",
    };
}
