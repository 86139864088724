// === src/app/renderer.ts ===

import { Logger } from "../../logger";
import { ChapterData, ContentData, Emotion, SectionData, NavigationData } from "../../../data/types";
import { SectionFactory } from "./section/sectionFactory";
import { ContentElementFactory } from "./content/contentFactory";

/**
 * Renderer: Responsible for rendering chapter content and managing interactions.
 */
export class Renderer {
    logger: Logger;
    private sectionFactory: SectionFactory;
    contentFactory: ContentElementFactory;
    private actionHandlers: Record<string, (payload?: any) => void> = {};

    constructor() {
        this.logger = new Logger('Renderer');
        this.sectionFactory = new SectionFactory(this);
        this.contentFactory = new ContentElementFactory(this);

        this.initializeEventHandlers();
        this.registerDefaultActionHandlers();
    }

    /**
     * Initializes and binds event listeners for custom events emitted by components.
     * Utilizes event delegation to optimize performance.
     */
    private initializeEventHandlers(): void {
        const events: string[] = [
            'element-clicked',
            'reflection-submitted',
            'mirror-toggled',
            'quiz-completed',
            'rating-changed',
            'modal-closed',
            'carousel-item-changed',
            'progress-completed',
            // Add more custom event names as needed
        ];

        events.forEach(eventName => {
            document.addEventListener(eventName, this.handleEvent.bind(this) as EventListener);
        });

        this.logger.info('Initialized event handlers for Renderer.');
    }

    /**
     * Generic event handler to manage custom events.
     * @param event - The Event object.
     */
    private handleEvent(event: Event): void {
        const customEvent = event as CustomEvent;

        if (!customEvent.detail || typeof customEvent.detail.type !== 'string') {
            this.logger.warn('Received malformed custom event without type.');
            return;
        }

        const { type, payload } = customEvent.detail;

        this.logger.info(`Handling event: ${type}`, payload);

        const handler = this.actionHandlers[type];
        if (handler) {
            try {
                handler(payload);
            } catch (error) {
                this.logger.error(`Error executing handler for action "${type}":`, error);
                this.showFeedback('An unexpected error occurred while processing your request.');
            }
        } else {
            this.logger.warn(`No handler registered for event type: ${type}`);
            this.showFeedback(`Action "${type}" is not supported.`);
        }
    }

    /**
     * Registers default action handlers mapped to specific actions.
     */
    private registerDefaultActionHandlers(): void {
        this.actionHandlers = {
            'openModal': this.openModal.bind(this),
            'closeModal': this.closeModal.bind(this),
            'submitReflection': this.submitReflection.bind(this),
            'saveUserReflection': this.saveUserReflection.bind(this),
            'showQuizResults': this.showQuizResults.bind(this),
            'ratingChanged': this.processRatingChange.bind(this),
            'unlockLabyrinthDoor': this.unlockLabyrinthDoor.bind(this),
            'handleCarouselItemChange': this.handleCarouselItemChange.bind(this),
            'handleProgressComplete': this.handleProgressComplete.bind(this),
            // Add more action handlers as needed
        };

        this.logger.info('Registered default action handlers.');
    }

    /**
     * Renders the chapter content based on the provided data.
     * @param data - The chapter data object.
     */
    public async renderChapter(data: ChapterData): Promise<void> {
        const chapterContent = document.getElementById('chapter-content');
        if (!chapterContent) {
            this.logger.error('Chapter content container not found.');
            return;
        }

        try {
            chapterContent.innerHTML = ''; // Clear existing content

            if (!this.isValidChapterData(data)) {
                this.renderError(chapterContent, 'Invalid chapter data. Please check the content source.');
                return;
            }

            const { chapterNumber, title, description, sections, lore, previousChapter, nextChapter } = data;

            this.appendChapterHeading(chapterContent, chapterNumber, title);
            this.appendChapterDescription(chapterContent, description);

            for (const [index, section] of sections.entries()) {
                const sectionElement = await this.appendSection(chapterContent, section, index);
                if (sectionElement) {
                    chapterContent.appendChild(sectionElement);
                }
            }

            if (lore?.loreItems?.length) {
                const loreSection = this.appendLoreSection(lore.loreItems);
                chapterContent.appendChild(loreSection);
            }

            if (previousChapter || nextChapter) {
                const navigation = this.appendNavigationLinks(previousChapter as any, nextChapter as any);
                chapterContent.appendChild(navigation);
            }

            chapterContent.setAttribute('tabindex', '-1');
            chapterContent.focus(); // Accessibility: focus main content

            this.logger.info(`Successfully rendered Chapter ${chapterNumber}: ${title}`);
        } catch (error) {
            this.logger.error('Error during renderChapter:', error);
            this.renderError(chapterContent, 'An unexpected error occurred. Please try again later.');
        }
    }

    /**
     * Validates the structure and content of the chapter data.
     * @param data - The chapter data object.
     * @returns True if valid, false otherwise.
     */
    private isValidChapterData(data: ChapterData): boolean {
        const { chapterNumber, title, description, sections } = data;
        const isValid = (
            typeof chapterNumber === 'number' &&
            title.trim().length > 0 &&
            description.trim().length > 0 &&
            Array.isArray(sections) &&
            sections.every(section => this.isValidSection(section))
        );

        if (!isValid) {
            this.logger.warn('Chapter data validation failed.');
        }

        return isValid;
    }

    /**
     * Validates individual sections within the chapter data.
     * @param section - A section data object.
     * @returns True if valid, false otherwise.
     */
    private isValidSection(section: SectionData | NavigationData): boolean {
        return !!section.type && typeof section.type === 'string';
    }

    /**
     * Renders an error message within the specified container.
     * @param container - The HTMLElement to render the error message in.
     * @param message - The error message to display.
     */
    private renderError(container: HTMLElement, message: string): void {
        container.innerHTML = `<p class="error-message" role="alert">${this.escapeHTML(message)}</p>`;
        this.logger.error(`Rendered error message: ${message}`);
    }

    /**
     * Appends the chapter heading to the container.
     * @param container - The parent HTMLElement.
     * @param number - The chapter number.
     * @param title - The chapter title.
     */
    private appendChapterHeading(container: HTMLElement, number: number, title: string): void {
        const heading = document.createElement('h1');
        heading.textContent = `Chapter ${this.padChapterNumber(number)}: ${title}`;
        container.appendChild(heading);
        this.logger.info(`Added chapter heading: ${heading.textContent}`);
    }

    /**
     * Appends the chapter description to the container.
     * @param container - The parent HTMLElement.
     * @param description - The chapter description.
     */
    private appendChapterDescription(container: HTMLElement, description: string): void {
        const descElement = document.createElement('p');
        descElement.textContent = description;
        container.appendChild(descElement);
        this.logger.info('Added chapter description.');
    }

    /**
     * Appends a section to the container by creating appropriate custom elements.
     * @param container - The parent HTMLElement.
     * @param section - The section data object.
     * @param index - The index of the section.
     * @returns The rendered HTMLElement or null.
     */
    private async appendSection(container: HTMLElement, section: SectionData | NavigationData, index: number): Promise<HTMLElement | null> {
        try {
            const sectionInstance = this.sectionFactory.createSection(section, index);
            if (!sectionInstance) {
                this.logger.warn(`Section instance creation failed for section type: ${section.type}`);
                return null;
            }

            const sectionElement = await sectionInstance.render();
            if (sectionElement) {
                this.logger.info(`Rendered section ${index + 1}: ${section.type}`);
                return sectionElement;
            }

            this.logger.warn(`Failed to render section ${index + 1}: ${section.type}`);
            return null;
        } catch (error) {
            this.logger.error(`Error rendering section ${index + 1} (${section.type}):`, error);
            return null;
        }
    }

    /**
     * Appends the lore section to the container.
     * @param loreItems - An array of lore items.
     * @returns The rendered HTMLElement for the lore section.
     */
    private appendLoreSection(loreItems: Array<{ description: string }>): HTMLElement {
        const loreSection = document.createElement('section');
        const loreHeading = document.createElement('h2');
        loreHeading.textContent = 'Lore';
        loreSection.appendChild(loreHeading);

        const loreList = document.createElement('ul');
        loreList.id = 'lore-collector';

        loreItems.forEach(item => {
            const listItem = document.createElement('li');
            listItem.textContent = item.description;
            loreList.appendChild(listItem);
        });

        loreSection.appendChild(loreList);
        this.logger.info('Appended lore section.');
        return loreSection;
    }

    /**
     * Appends navigation links (Previous and Next) to the container.
     * @param previousChapter - The URL or identifier for the previous chapter.
     * @param nextChapter - The URL or identifier for the next chapter.
     * @returns The rendered HTMLElement for the navigation section.
     */
    private appendNavigationLinks(previousChapter?: string, nextChapter?: string): HTMLElement {
        const nav = document.createElement('nav');
        const navList = document.createElement('ul');
        navList.classList.add('chapter-navigation');

        if (previousChapter) {
            const prevItem = this.createNavItem('← Previous Chapter', previousChapter);
            navList.appendChild(prevItem);
        }

        if (nextChapter) {
            const nextItem = this.createNavItem('Next Chapter →', nextChapter);
            navList.appendChild(nextItem);
        }

        nav.appendChild(navList);
        this.logger.info('Appended navigation section.');
        return nav;
    }

    /**
     * Creates a navigation list item with a link.
     * @param text - The display text for the link.
     * @param href - The URL or identifier the link points to.
     * @returns An HTMLElement representing the navigation item.
     */
    private createNavItem(text: string, href: string): HTMLElement {
        const listItem = document.createElement('li');
        const link = document.createElement('a');
        link.href = href;
        link.textContent = text;
        link.setAttribute('data-router', '');
        link.setAttribute('aria-label', text);
        listItem.appendChild(link);
        return listItem;
    }

    /**
     * Pads the chapter number with leading zeros for consistent formatting.
     * @param number - The chapter number.
     * @returns A string representing the padded chapter number.
     */
    private padChapterNumber(number: number | string): string {
        return number.toString().padStart(2, '0');
    }

    /**
     * Escapes HTML special characters to prevent XSS attacks.
     * @param unsafe - The string potentially containing HTML.
     * @returns A sanitized string safe for insertion into the DOM.
     */
    private escapeHTML(unsafe: string): string {
        return unsafe.replace(/[&<"'>]/g, (match) => {
            const escape: Record<string, string> = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#039;',
            };
            return escape[match];
        });
    }

    /**
     * Applies emotion attributes to the given element based on the Emotion interface.
     * Emotion-based styling is handled by the EmotionalInteractor; no inline styles are applied here.
     * @param element - The HTMLElement to apply attributes to.
     * @param emotion - The Emotion object containing valence, arousal, and discrete emotions.
     */
    public applyEmotionAttributes(element: HTMLElement, emotion: Emotion): void {
        if (!emotion) {
            this.logger.warn(`Emotion object is undefined or null.`);
            return;
        }

        const { valence = 5, arousal = 5, discrete = [] } = emotion;

        // Validate valence and arousal
        if (typeof valence !== 'number' || typeof arousal !== 'number') {
            this.logger.warn('Emotion valence and arousal should be numbers.');
            return;
        }

        element.setAttribute('data-emotion-valence', valence.toString());
        element.setAttribute('data-emotion-arousal', arousal.toString());

        const sanitizedDiscrete = discrete
            .filter(e => typeof e === 'string')
            .map(e => e.replace(/[^a-z0-9\-]/gi, '').toLowerCase());

        sanitizedDiscrete.forEach(emotionClass => element.classList.add(`emotion-${emotionClass}`));

        this.logger.debug(`Applied emotion attributes to element: valence=${valence}, arousal=${arousal}, discrete=${sanitizedDiscrete.join(', ')}`);
    }

    /**
     * Handles actions triggered by interactive elements.
     * @param action - The action identifier to handle.
     * @param payload - Optional payload associated with the action.
     */
    public handleAction(action?: string, payload?: any): void {
        if (!action) return;
        this.logger.info(`Handling action: ${action}`, payload);
        const handler = this.actionHandlers[action];
        if (handler) {
            try {
                handler(payload);
            } catch (error) {
                this.logger.error(`Error executing action handler for "${action}":`, error);
                this.showFeedback('An unexpected error occurred while processing your request.');
            }
        } else {
            this.logger.warn(`No handler registered for action: ${action}`);
            this.showFeedback(`Action "${action}" is not supported.`);
        }
    }

    // Action Handlers

    /**
     * Opens a modal based on the provided modalId.
     * @param payload - Contains the modalId to open and optional content.
     */
    private openModal(payload: { modalId: string; content?: string }): void {
        const { modalId, content } = payload;
        if (!modalId) {
            this.logger.warn('openModal action received without modalId.');
            this.showFeedback('Unable to open modal: Missing modal identifier.');
            return;
        }

        let modal: any = document.querySelector(`custom-modal[modal-id="${modalId}"]`);

        if (!modal) {
            modal = document.createElement('custom-modal');
            modal.setAttribute('modal-id', modalId);
            modal.title = 'Modal';

            if (content) {
                // Sanitize content before inserting
                modal.innerHTML = this.escapeHTML(content);
            }

            document.body.appendChild(modal);
            this.logger.info(`Created and appended new modal with ID: ${modalId}`);
        }

        modal.isOpen = true;
        this.logger.info(`Opened modal with ID: ${modalId}`);
    }

    /**
     * Closes a modal based on the provided modalId.
     * @param payload - Contains the modalId to close.
     */
    private closeModal(payload: { modalId: string }): void {
        const { modalId } = payload;
        if (!modalId) {
            this.logger.warn('closeModal action received without modalId.');
            this.showFeedback('Unable to close modal: Missing modal identifier.');
            return;
        }

        const modal: any = document.querySelector(`custom-modal[modal-id="${modalId}"]`);

        if (modal && modal.isOpen) {
            modal.isOpen = false;
            this.logger.info(`Closed modal with ID: ${modalId}`);
        } else {
            this.logger.warn(`Modal with ID: ${modalId} not found or already closed.`);
            this.showFeedback(`Modal "${modalId}" is not open.`);
        }
    }

    /**
     * Processes the reflection submission.
     * @param payload - Contains reflectionId and text.
     */
    private submitReflection(payload: { reflectionId: string; text: string }): void {
        const { reflectionId, text } = payload;

        if (!reflectionId || !text) {
            this.logger.warn('submitReflection action received incomplete payload.');
            this.showFeedback('Reflection submission failed: Missing information.');
            return;
        }

        // Process the reflection submission, e.g., save to server or local storage
        this.logger.info(`Processing reflection ${reflectionId}: ${text}`);

        // Example: Save to localStorage with proper error handling
        try {
            const reflections = JSON.parse(localStorage.getItem('reflections') || '{}');
            reflections[reflectionId] = text;
            localStorage.setItem('reflections', JSON.stringify(reflections));
            this.showFeedback('Thank you for your reflection!');
            this.logger.info(`Reflection ${reflectionId} saved successfully.`);
        } catch (error) {
            this.logger.error('Error saving reflection:', error);
            this.showFeedback('Failed to save your reflection. Please try again.');
        }
    }

    /**
     * Saves user reflection manually (if needed).
     * @param payload - Contains reflectionId and text.
     */
    private saveUserReflection(payload: { reflectionId: string; text: string }): void {
        this.submitReflection(payload);
    }

    /**
     * Displays quiz results to the user in a modal.
     * @param payload - Contains score and total.
     */
    private showQuizResults(payload: { score: number; total: number }): void {
        const { score, total } = payload;

        if (typeof score !== 'number' || typeof total !== 'number') {
            this.logger.warn('showQuizResults action received invalid payload.');
            this.showFeedback('Unable to display quiz results due to missing information.');
            return;
        }

        const content = `<p>You scored ${score} out of ${total}!</p>`;
        this.openModal({ modalId: 'quizResultsModal', content });
        this.logger.info(`Displayed quiz results: ${score}/${total}`);
    }

    /**
     * Processes changes in user ratings.
     * @param value - The new rating value.
     */
    private processRatingChange(value: number): void {
        if (typeof value !== 'number') {
            this.logger.warn('processRatingChange action received non-numeric value.');
            this.showFeedback('Invalid rating value.');
            return;
        }

        this.logger.info(`User rating changed to ${value}`);

        // Example: Send the rating to an API endpoint with error handling
        /*
        fetch('/api/ratings', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ rating: value }),
        })
            .then(response => response.json())
            .then(data => {
                this.logger.info('Rating submitted successfully:', data);
                this.showFeedback('Your rating has been submitted. Thank you!');
            })
            .catch(error => {
                this.logger.error('Error submitting rating:', error);
                this.showFeedback('Failed to submit your rating. Please try again.');
            });
        */

        // For demonstration purposes, we'll just show feedback
        this.showFeedback(`You rated this chapter ${value}/5`);
    }

    /**
     * Unlocks the labyrinth door after solving the puzzle.
     * @param payload - Contains any relevant data (optional).
     */
    private unlockLabyrinthDoor(payload?: any): void {
        const labyrinth: any = document.querySelector('custom-puzzle[puzzle-id="labyrinthPuzzle"]');
        if (labyrinth) {
            if (typeof labyrinth.unlock === 'function') {
                labyrinth.unlock(); // Assuming the custom-puzzle element has an unlock method
                this.showFeedback('Labyrinth door unlocked!');
                this.logger.info('Labyrinth door unlocked.');
            } else {
                this.logger.warn('custom-puzzle element does not have an unlock method.');
                this.showFeedback('Unable to unlock the labyrinth door.');
            }
        } else {
            this.logger.warn('Labyrinth puzzle element not found.');
            this.showFeedback('Labyrinth puzzle is not available.');
        }
    }

    /**
     * Handles carousel item change events.
     * @param payload - Contains the current index or any relevant data.
     */
    private handleCarouselItemChange(payload: { currentIndex: number }): void {
        if (typeof payload.currentIndex !== 'number') {
            this.logger.warn('handleCarouselItemChange action received invalid payload.');
            this.showFeedback('Carousel item change encountered an issue.');
            return;
        }

        this.logger.info(`Carousel item changed to index: ${payload.currentIndex}`);
        // Implement any additional logic if needed
    }

    /**
     * Handles completion of progress tracking.
     * @param payload - Contains any relevant data (optional).
     */
    private handleProgressComplete(payload?: any): void {
        this.showFeedback('Chapter progress completed!');
        this.logger.info('Chapter progress completed.');
        // Implement any additional logic if needed
    }

    /**
     * Displays user feedback messages.
     * @param message - The feedback message to display.
     */
    private showFeedback(message: string): void {
        if (!message) return;

        const feedback = document.createElement('div');
        feedback.textContent = message;
        feedback.classList.add('reflection-feedback');
        feedback.setAttribute('role', 'status');
        feedback.setAttribute('aria-live', 'polite');

        // Style the feedback message (optional)
        Object.assign(feedback.style, {
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '5px',
            opacity: '0',
            transition: 'opacity 0.5s ease',
            zIndex: '1000',
        });

        document.body.appendChild(feedback);

        // Trigger fade-in
        requestAnimationFrame(() => {
            feedback.style.opacity = '1';
        });

        // Remove after 3 seconds with fade-out
        setTimeout(() => {
            feedback.style.opacity = '0';
            feedback.addEventListener('transitionend', () => {
                if (feedback.parentElement) {
                    feedback.parentElement.removeChild(feedback);
                }
            });
        }, 3000);

        this.logger.info(`Displayed feedback message: ${message}`);
    }

    /**
     * Additional methods for enhanced functionality can be added here
     */
}
