// src/app/logger.ts

export class Logger {
  moduleName: string;

  constructor(moduleName: string) {
    this.moduleName = moduleName;
  }

  private _log(level: keyof Console, message: string, ...args: any[]) {
    const timestamp = new Date().toISOString();

    if (console[level]) {
      console[level as 'log'](`[${timestamp}] [${this.moduleName}] ${message}`, ...args);
    } else {
      console.log(`[${timestamp}] [${this.moduleName}] ${message}`, ...args);
    }
  }

  info(message: string, ...args: any[]) {
    this._log('info', message, ...args);
  }

  debug(message: string, ...args: any[]) {
    this._log('debug', message, ...args);
  }

  warn(message: string, ...args: any[]) {
    this._log('warn', message, ...args);
  }

  error(message: string, ...args: any[]) {
    this._log('error', message, ...args);
  }
}

