// === custom/customTooltip.ts ===
import { customElement, property } from "lit/decorators";
import { css, html, LitElement, TemplateResult } from "lit";
import { Logger } from "./baseModal";

/**
 * CustomTooltip: Displays additional information on hover or focus.
 * Supports positioning (`top`, `bottom`, `left`, `right`) and can include HTML content.
 */
@customElement('custom-tooltip')
class CustomTooltip extends LitElement {
    @property({ type: String }) text: string = '';
    @property({ type: String }) position: 'top' | 'bottom' | 'left' | 'right' = 'top';
    @property({ type: Boolean }) interactive: boolean = false;

    static styles = css`
        .tooltip-wrapper {
            position: relative;
            display: inline-block;
        }

        .tooltip {
            position: absolute;
            background-color: var(--color-text-neutral, #333);
            color: var(--color-text-content, #fff);
            padding: var(--spacing-xs, 0.5rem) var(--spacing-sm, 0.75rem);
            border-radius: var(--border-radius-sm, 0.25rem);
            font-size: var(--font-size-tooltip, 0.9rem);
            white-space: nowrap;
            box-shadow: var(--shadow-sm, 0 1px 3px rgba(0, 0, 0, 0.3));
            opacity: 0;
            visibility: hidden;
            transition: opacity var(--transition-speed-fast, 0.3s) ease, visibility var(--transition-speed-fast, 0.3s) ease;
            z-index: var(--z-index-tooltip, 10);
            pointer-events: none;
        }

        .tooltip::after {
            content: '';
            position: absolute;
            border-width: 5px;
            border-style: solid;
        }

        /* Positioning */
        .top {
            bottom: 125%;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: var(--spacing-xs, 0.5rem);
        }

        .bottom {
            top: 125%;
            left: 50%;
            transform: translateX(-50%);
            margin-top: var(--spacing-xs, 0.5rem);
        }

        .left {
            top: 50%;
            right: 125%;
            transform: translateY(-50%);
            margin-right: var(--spacing-xs, 0.5rem);
        }

        .right {
            top: 50%;
            left: 125%;
            transform: translateY(-50%);
            margin-left: var(--spacing-xs, 0.5rem);
        }

        /* Arrows */
        .top::after {
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border-color: var(--color-text-neutral, #333) transparent transparent transparent;
        }

        .bottom::after {
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            border-color: transparent transparent var(--color-text-neutral, #333) transparent;
        }

        .left::after {
            top: 50%;
            left: 100%;
            transform: translateY(-50%);
            border-color: transparent transparent transparent var(--color-text-neutral, #333);
        }

        .right::after {
            top: 50%;
            right: 100%;
            transform: translateY(-50%);
            border-color: transparent var(--color-text-neutral, #333) transparent transparent;
        }

        /* Interactive Tooltip */
        .interactive .tooltip {
            pointer-events: auto;
        }

        .interactive:hover .tooltip,
        .interactive:focus-within .tooltip {
            opacity: 1;
            visibility: visible;
        }

        /* Static Tooltip */
        :host(:hover) .tooltip,
        :host(:focus-within) .tooltip {
            opacity: 1;
            visibility: visible;
        }
    `;

    private logger = new Logger('CustomTooltip');

    render(): TemplateResult {
        return html`
            <div class="tooltip-wrapper ${this.interactive ? 'interactive' : ''}">
                <slot></slot>
                <div class="tooltip ${this.position}">
                    ${this.text}
                </div>
            </div>
        `;
    }
}
