// === custom/customWhisper.ts ===
import { customElement, property, state } from "lit/decorators";
import { css, html, LitElement, TemplateResult } from "lit";
import { Logger, sharedStyles } from "./baseModal";

/**
 * CustomWhisper: Displays whispered or subdued text.
 * Enhances accessibility and provides interactive features.
 */
@customElement('custom-whisper')
class CustomWhisper extends LitElement {
    @property({ type: String }) text: string = '';
    @property({ type: String }) author: string = '';
    @property({ type: Boolean }) interactive: boolean = false;

    @state() private isExpanded: boolean = false;

    static styles = [
        sharedStyles,
        css`
            .whisper-container {
                background: var(--color-bg-whisper, rgba(211, 211, 211, 0.1)); /* Light gray */
                border-left: 4px solid var(--color-primary, #696969); /* Dim gray */
                padding: var(--spacing-sm, 0.75rem) var(--spacing-md, 1rem);
                margin: var(--spacing-lg, 1rem) 0;
                border-radius: var(--border-radius-sm, 0.25rem);
                font-style: italic;
                color: var(--color-text-alt, #696969);
                position: relative;
                cursor: pointer;
                transition: background-color var(--transition-speed-fast, 0.3s) ease,
                            transform var(--transition-speed-fast, 0.3s) ease,
                            box-shadow var(--transition-speed-fast, 0.3s) ease;
                display: flex;
                flex-direction: column;
                gap: var(--spacing-xs, 0.5rem);
            }

            .whisper-container:hover,
            .whisper-container:focus-within {
                background-color: var(--color-bg-valence-neutral, rgba(211, 211, 211, 0.2));
                transform: scale(var(--interactive-hover-scale, 1.02));
                box-shadow: var(--shadow-hover, 0 6px 12px rgba(0, 0, 0, 0.3));
            }

            .whisper-container:focus-within {
                outline: 2px dashed var(--color-accent-alt, #007bff);
                outline-offset: 4px;
            }

            .whisper-text {
                max-height: 5rem;
                overflow: hidden;
                transition: max-height 0.3s ease;
            }

            .toggle-button {
                background: none;
                border: none;
                color: var(--color-accent-alt, #007bff);
                cursor: pointer;
                font-size: var(--font-size-sm, 0.95rem);
                padding: 0;
                align-self: flex-end;
                transition: color var(--transition-speed-fast, 0.3s) ease;
            }

            .toggle-button:hover,
            .toggle-button:focus {
                text-decoration: underline;
            }

            .author {
                margin-top: var(--spacing-xs, 0.25rem);
                font-size: var(--font-size-caption, 0.9rem);
                color: var(--color-text-alt, #555);
                text-align: right;
            }
        `,
    ];

    private logger = new Logger('CustomWhisper');

    render(): TemplateResult {
        const text = this.text;
        console.log({text});
        return html`
            <div
                    class="whisper-container"
                    tabindex="${this.interactive ? '0' : '-1'}"
                    role="${this.interactive ? 'button' : 'note'}"
                    aria-label="${this.interactive ? 'Interactive Whisper' : 'Whispered Content'}"
                    @click="${this.handleClick}"
                    @keydown="${this.handleKeyPress}"
            >
                <div class="whisper-text">${this.text}</div>
                ${this.author ? html`<div class="author">— ${this.author}</div>` : ''}
            </div>
        `;
    }

    private isExpandable(): boolean {
        // Determine if the whisper text is long enough to be expandable
        return this.text.length > 100;
    }

    private toggleExpansion(event: Event): void {
        event.stopPropagation();
        this.isExpanded = !this.isExpanded;
        this.logger.info(`Whisper ${this.isExpanded ? 'expanded' : 'collapsed'}.`);
    }

    private handleClick(): void {
        if (!this.interactive) return;
        this.logger.info('Whisper container clicked.');
        this.dispatchEvent(
            new CustomEvent('element-clicked', {
                detail: { type: 'custom-whisper', state: 'clicked' },
                bubbles: true,
                composed: true,
            }),
        );
    }

    private handleKeyPress(event: KeyboardEvent): void {
        if (!this.interactive) return;
        if (['Enter', ' '].includes(event.key)) {
            event.preventDefault();
            this.handleClick();
        }
    }
}
