// === app/app.ts ===
// src/app/app.ts

import {Logger} from './logger';
import {EmotionalInteractor} from './emotion';
import {routes} from './routes';
import {ChapterData} from "../data/types";
import {Boonberry} from "../data/boonberry";
import {Flavor} from "../data/flavor";
import {Renderer} from "./ui/renderer/renderer";
import {Core} from "../data/core";
import './ui';

const Story = [Core, Boonberry, Flavor];

export class EReaderApp {
    emotionalInteractor: EmotionalInteractor;
    contentRenderer: Renderer;
    logger: Logger;

    constructor() {
        this.logger = new Logger('EReaderApp');
        this.contentRenderer = new Renderer();
        this.emotionalInteractor = new EmotionalInteractor();
    }

    async init() {
        document.addEventListener('DOMContentLoaded', () => this.handleDOMContentLoaded());
    }

    async fetchChapterData(chapterNumber: number): Promise<ChapterData> {
        if (chapterNumber < 0 || chapterNumber >= Story.length) {
            this.logger.warn(`Chapter number ${chapterNumber} is out of bounds.`);
            throw new Error('Chapter number out of bounds.');
        }
        const chapterFetcher = Story[chapterNumber];
        if (typeof chapterFetcher !== 'function') {
            this.logger.error(`Chapter fetcher for chapter ${chapterNumber} is not a function.`);
            throw new Error('Invalid chapter fetcher.');
        }
        return chapterFetcher(chapterNumber);
    }

    async handleDOMContentLoaded() {
        this.logger.info('DOM fully loaded and parsed.');
        try {
            const path = window.location.pathname;
            const hasRendered = await this.render(path);
            if (!hasRendered) {
                this.logger.warn('No matching route found.');
            }
        } catch (error) {
            this.logger.error('Error during initialization:', error);
        }
    }

    /**
     * Renders content based on the current path.
     * @param path - The current window location path.
     * @returns True if a route was matched and rendered, else false.
     */
    private async render(path: string): Promise<boolean> {
        let match: string | null;
        match = path.match(routes.chapter.pattern)?.[1] || null;
        let chapterData: ChapterData;
        let isPathEmpty = !path.split('/').join('').trim();
        if (isPathEmpty) {
            chapterData = await this.fetchChapterData(0);
            this.contentRenderer.renderChapter(chapterData);
            return true;
        }

        if (match) {
            const chapterNumber = parseInt(match, 10) - 1;
            chapterData = await this.fetchChapterData(chapterNumber);
            this.contentRenderer.renderChapter(chapterData);
            return true;
        }
        return false;
    }
}
