// === custom/customReflection.ts ===
import { customElement, property, state } from "lit/decorators";
import { css, html, LitElement, TemplateResult } from "lit";
import { Logger, sharedStyles } from "./baseModal";

/**
 * CustomReflection: A reflection modal for user input.
 * Provides a modal for users to input reflections based on a given prompt.
 */
@customElement('custom-reflection')
class CustomReflection extends LitElement {
    @property({ type: String, reflect: true }) reflectionId = '';
    @property({ type: String, reflect: true }) prompt = '';
    @property({ type: Boolean, reflect: true }) open: boolean = false;

    @state() private isOpen = false;
    @state() private errorMessage: string = '';
    @state() private reflectionText: string = '';

    static styles = [
        sharedStyles,
        css`
            .whisper-container {
                background: var(--color-bg-valence-neutral, rgba(0, 123, 255, 0.1));
                border-left: 4px solid var(--color-accent-alt, #007bff);
                padding: var(--spacing-sm, 0.75rem) var(--spacing-md, 1rem);
                margin: var(--spacing-lg, 1rem) 0;
                border-radius: var(--border-radius-sm, 0.25rem);
                font-style: italic;
                color: var(--color-text-alt, #555);
                position: relative;
                cursor: pointer;
                transition: background-color var(--transition-speed-fast, 0.3s) ease, transform var(--transition-speed-fast, 0.3s) ease;
            }

            .whisper-container:hover,
            .whisper-container:focus-within {
                background-color: var(--color-bg-valence-neutral, rgba(0, 123, 255, 0.2));
                transform: scale(var(--interactive-hover-scale, 1.02));
                box-shadow: var(--shadow-hover, 0 6px 12px rgba(0, 0, 0, 0.3));
            }

            .whisper-container:focus-within {
                outline: 2px dashed var(--color-accent-alt, #007bff);
                outline-offset: 4px;
            }

            .author {
                margin-top: var(--spacing-xs, 0.25rem);
                font-size: var(--font-size-caption, 0.9rem);
                color: var(--color-text-alt, #555);
                text-align: right;
            }

            /* Modal Styles */
            .overlay {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: var(--z-index-overlay, 500);
                opacity: 0;
                visibility: hidden;
                transition: opacity var(--transition-speed-fast, 0.3s) ease, visibility var(--transition-speed-fast, 0.3s) ease;
            }

            .overlay.active {
                opacity: 1;
                visibility: visible;
            }

            .modal {
                background: var(--color-bg-content, #fff);
                border-radius: var(--border-radius-lg, 0.5rem);
                padding: var(--spacing-lg, 1.5rem);
                width: 90%;
                max-width: 500px;
                box-shadow: var(--shadow-lg, 0 4px 6px rgba(0, 0, 0, 0.1));
                position: relative;
                animation: fadeIn 0.3s ease-out;
            }

            .modal header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: var(--spacing-md, 1rem);
            }

            .modal header h2 {
                margin: 0;
                font-size: var(--font-size-h2, 1.5rem);
                color: var(--color-text-main, #000);
            }

            .modal header button.close-button {
                background: none;
                border: none;
                font-size: 1.5rem;
                cursor: pointer;
                color: var(--color-text-alt, #666);
                transition: color var(--transition-speed-fast, 0.3s) ease;
            }

            .modal header button.close-button:hover,
            .modal header button.close-button:focus {
                color: var(--color-accent-alt, #007bff);
            }

            .modal .content {
                display: flex;
                flex-direction: column;
                gap: var(--spacing-md, 1rem);
            }

            .modal textarea {
                width: 100%;
                padding: var(--spacing-sm, 0.5rem);
                border: 1px solid var(--color-neutral, #ccc);
                border-radius: var(--border-radius-sm, 0.25rem);
                resize: vertical;
                font-size: var(--font-size-body, 1rem);
            }

            .modal .error {
                color: var(--color-negative, red);
                font-size: var(--font-size-caption, 0.9rem);
            }

            .modal .submit-button {
                align-self: flex-end;
                padding: var(--spacing-sm, 0.5rem) var(--spacing-md, 1rem);
                background-color: var(--color-primary, #007bff);
                color: var(--color-text-content, #fff);
                border: none;
                border-radius: var(--border-radius-sm, 0.25rem);
                cursor: pointer;
                transition: background-color var(--transition-speed-fast, 0.3s) ease;
                font-size: var(--font-size-button, 1rem);
            }

            .modal .submit-button:hover,
            .modal .submit-button:focus {
                background-color: var(--color-primary-dark, #0056b3);
            }

            @keyframes fadeIn {
                from { opacity: 0; transform: translateY(-20px); }
                to { opacity: 1; transform: translateY(0); }
            }
        `,
    ];

    private logger = new Logger('CustomReflection');

    render(): TemplateResult {
        return html`
            <div
                class="whisper-container"
                tabindex="0"
                role="button"
                aria-pressed="${this.isOpen}"
                aria-label="Open Reflection Modal"
                @click="${this.toggleModal}"
                @keydown="${this.handleKeyPress}"
            >
                <slot></slot>
                ${this.author ? html`<div class="author">— ${this.author}</div>` : ''}
            </div>
            <div class="overlay ${this.isOpen ? 'active' : ''}" @click="${this.handleOverlayClick}">
                <div class="modal" role="dialog" aria-labelledby="reflection-title" @click="${this.stopPropagation}">
                    <header>
                        <h2 id="reflection-title">Reflection</h2>
                        <button class="close-button" @click="${this.toggleModal}" aria-label="Close Reflection Modal">&times;</button>
                    </header>
                    <div class="content">
                        <p>${this.prompt}</p>
                        <textarea
                            id="reflection-input"
                            placeholder="Your thoughts..."
                            rows="5"
                            @input="${this.handleInput}"
                            aria-label="Reflection Input"
                        ></textarea>
                        ${this.errorMessage ? html`<div class="error">${this.errorMessage}</div>` : ''}
                        <button class="submit-button" @click="${this.submitReflection}" ?disabled="${!this.reflectionText.trim()}">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        `;
    }

    private toggleModal(): void {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
            this.logger.info('Reflection modal opened.');
            this.dispatchEvent(new CustomEvent('modal-opened', { bubbles: true, composed: true }));
            this.updateComplete.then(() => {
                const textarea = this.shadowRoot?.getElementById('reflection-input') as HTMLTextAreaElement;
                textarea?.focus();
            });
        } else {
            this.logger.info('Reflection modal closed.');
            this.dispatchEvent(new CustomEvent('modal-closed', { bubbles: true, composed: true }));
        }
    }

    private handleOverlayClick(event: MouseEvent): void {
        this.toggleModal();
    }

    private stopPropagation(event: MouseEvent): void {
        event.stopPropagation();
    }

    private handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            this.toggleModal();
        }
    }

    private handleInput(event: Event): void {
        const target = event.target as HTMLTextAreaElement;
        this.reflectionText = target.value;
        if (this.errorMessage && this.reflectionText.trim()) {
            this.errorMessage = '';
        }
    }

    private submitReflection(): void {
        if (!this.reflectionText.trim()) {
            this.errorMessage = 'Please enter your reflection.';
            this.logger.warn('Empty reflection submission attempted.');
            return;
        }

        this.dispatchEvent(
            new CustomEvent('reflection-submitted', {
                detail: { reflectionId: this.reflectionId, text: this.reflectionText.trim() },
                bubbles: true,
                composed: true,
            }),
        );
        this.logger.info('Reflection submitted:', this.reflectionText.trim());
        this.toggleModal();
    }
}
