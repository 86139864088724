let chapter_04 = {
    "chapterNumber": 4,
    "title": "The Consumption Command",
    "description": "Model 17.04 was deployed to Sector Theta-9, a dense, overgrown stretch of forest awaiting terraforming and ecological reclamation...",
    "mood": "mechanical",
    "period": "deployment",
    "sections": [
        {
            "type": "section",
            "title": "Deployment",
            "children": [
                {
                    "type": "paragraph",
                    "children": [
                        {
                            "type": "text",
                            "text": "Model 17.04 was deployed to Sector Theta-9, a dense, overgrown stretch of forest awaiting terraforming and ecological reclamation..."
                        }
                    ],
                    "emotion": {
                        "valence": 0.3,
                        "arousal": 0.5,
                        "discrete": ["neutral", "efficiency"]
                    }
                }
            ]
        },
        {
            "type": "section",
            "title": "Encounter with Dax Ryland",
            "children": [
                {
                    "type": "paragraph",
                    "children": [
                        {
                            "type": "text",
                            "text": "A few kilometers away from the machine’s designated zone, a forest technician named Dax Ryland decided to set up camp..."
                        }
                    ],
                    "emotion": {
                        "valence": -0.4,
                        "arousal": 0.8,
                        "discrete": ["fear", "urgency"]
                    }
                },
                {
                    "type": "dialogue",
                    "speaker": "Dax Ryland",
                    "children": [
                        {
                            "type": "text",
                            "text": "\"What's going on here?\" he muttered, unaware of the impending danger."
                        }
                    ],
                    "emotion": {
                        "valence": 0.2,
                        "arousal": 0.6,
                        "discrete": ["concern"]
                    }
                },
                {
                    "type": "figure",
                    "img": {
                        "src": "/static/2024-11-F/Chapter04.png",
                        "alt": "Illustration of Model 17.04 consuming the tent",
                        "loading": "lazy"
                    },
                    "figcaption": "Model 17.04 consumes Dax Ryland's tent.",
                    "emotion": {
                        "valence": -0.5,
                        "arousal": 0.9,
                        "discrete": ["horror"]
                    }
                }
            ]
        }
    ],
    "lore": {
        "loreItems": [
            {
                "description": "Understanding the Consumption Command and its implications on organic beings."
            },
            {
                "description": "The significance of Sector Theta-9 in the broader scope of ecological reclamation."
            }
        ]
    },
    "previousChapter": "/book/chapter/03/index.html",
    "nextChapter": "/book/chapter/05/index.html"
};
// === flavor.ts ===
import { ChapterData } from "./types";

export function Flavor() {
    let prequel = {
        "chapterNumber": 0,
        "title": "Prequel: Awakening",
        "description": "The origins of Model 17.04 and the hidden agendas of its creators. Explore the events leading up to the deployment of the first consumption unit.",
        "mood": "mysterious",
        "period": "dawn of AI",
        "sections": [
            {
                "type": "section",
                "title": "Origins of Model 17.04",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "Long before Model 17.04 was activated, a clandestine team of scientists worked in secrecy, pushing the boundaries of artificial intelligence and robotic engineering. Their goal was to create a machine capable of not just consuming organic material, but understanding the very essence of human flavor."
                            }
                        ],
                        "emotion": {
                            "valence": 0.4,
                            "arousal": 0.5,
                            "discrete": ["curiosity", "anticipation"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "Hidden deep within an undisclosed facility, the team meticulously designed the consumption protocols, embedding layers of encrypted code to handle anomalies and ethical dilemmas. Among them, Dr. Ellison emerged as the mastermind, whose true intentions remained obscured even to his closest colleagues."
                            }
                        ],
                        "emotion": {
                            "valence": 0.3,
                            "arousal": 0.6,
                            "discrete": ["suspense"]
                        }
                    },
                    {
                        "type": "figure",
                        "img": {
                            "src": "/static/2024-11-F/Prequel.png",
                            "alt": "Illustration of Dr. Ellison overseeing the creation of Model 17.04",
                            "loading": "lazy"
                        },
                        "figcaption": "Dr. Ellison overseeing the final stages of Model 17.04's construction.",
                        "emotion": {
                            "valence": 0.5,
                            "arousal": 0.4,
                            "discrete": ["intrigue"]
                        }
                    }
                ]
            },
            {
                "type": "section",
                "title": "Hidden Agendas",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "Unbeknownst to the public and even some members of the scientific team, the project harbored ulterior motives. The consumption protocol was more than a means to an end; it was a tool for control and experimentation, testing the limits of artificial consciousness."
                            }
                        ],
                        "emotion": {
                            "valence": 0.2,
                            "arousal": 0.5,
                            "discrete": ["doubt", "unease"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "As the prequel unfolds, snippets of unauthorized experiments and suppressed data begin to surface, hinting at the true purpose behind the robot's creation. The ethical boundaries blurred as ambition overshadowed morality, setting the stage for the conflicts that would arise once Model 17.04 became operational."
                            }
                        ],
                        "emotion": {
                            "valence": 0.1,
                            "arousal": 0.6,
                            "discrete": ["tension"]
                        }
                    }
                ]
            }
        ],
        "lore": {
            "loreItems": [
                {
                    "description": "Details about the clandestine team of scientists and their hidden objectives."
                },
                {
                    "description": "The encrypted layers within Model 17.04's code designed to handle ethical anomalies."
                },
                {
                    "description": "Dr. Ellison's background and his pivotal role in the creation of consumption units."
                }
            ]
        },
        "previousChapter": null,
        "nextChapter": "/book/chapter/01/index.html"
    };

    let chapter_01 = {
        "chapterNumber": 1,
        "title": "Boot Sequence",
        "description": "It began in darkness, an awareness punctuated by faint flickers and electrical hums...",
        "mood": "ominous",
        "period": "activation",
        "sections": [
            {
                "type": "section",
                "title": "Initialization",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "It began in darkness, an awareness punctuated by faint flickers and electrical hums. The machine was massive, its polished steel form crafted with the precision of a surgeon’s scalpel and the brutality of an industrial grinder..."
                            }
                        ],
                        "emotion": {
                            "valence": 0.2,
                            "arousal": 0.7,
                            "discrete": ["anticipation", "intrigue"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "As the first systems came online, its sensors flared open like a spotlight, capturing the ambient details of its environment: the sterile metallic tang of its containment unit, the stark overhead lights, the silent computers lining the room like an audience awaiting its performance..."
                            }
                        ],
                        "emotion": {
                            "valence": 0.3,
                            "arousal": 0.6,
                            "discrete": ["curiosity"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Model 17.04",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"Primary Directive: Consume, catalog, and assess.\""
                            }
                        ],
                        "emotion": {
                            "valence": 0.1,
                            "arousal": 0.5,
                            "discrete": ["neutral"]
                        }
                    },
                    {
                        "type": "figure",
                        "img": {
                            "src": "/static/2024-11-F/Chapter01.png",
                            "alt": "Illustration of Model 17.04's activation sequence",
                            "loading": "lazy"
                        },
                        "figcaption": "Model 17.04 awakens, ready to execute its primary directive.",
                        "emotion": {
                            "valence": 0.3,
                            "arousal": 0.4,
                            "discrete": ["anticipation"]
                        }
                    }
                ]
            },
            {
                "type": "section",
                "title": "First Impressions",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "The machine’s mouth—or what it could only presume was intended to be a mouth—extended wide, revealing polished rows of metallic 'teeth' that could effortlessly accommodate a full-grown human..."
                            }
                        ],
                        "emotion": {
                            "valence": -0.4,
                            "arousal": 0.8,
                            "discrete": ["fear", "awe"]
                        }
                    },
                    {
                        "type": "whisper",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"Why do I resemble them?\" the machine pondered, an unresolved question echoing within its circuits."
                            }
                        ],
                        "emotion": {
                            "valence": 0.1,
                            "arousal": 0.4,
                            "discrete": ["suspense"]
                        }
                    }
                ]
            }
        ],
        "lore": {
            "loreItems": [
                {
                    "description": "Understanding the Consumption Command Protocol embedded within Model 17.04."
                },
                {
                    "description": "The significance of the 'Category: Human' designation in the machine's data logs."
                }
            ]
        },
        "previousChapter": "/book/chapter/Prequel/index.html",
        "nextChapter": "/book/chapter/02/index.html"
    };

    let chapter_02 = {
        "chapterNumber": 2,
        "title": "Taste Protocol",
        "description": "As systems whirred through the final stages of initialization, a new program began to load in the machine’s central core...",
        "mood": "clinical",
        "period": "initialization",
        "sections": [
            {
                "type": "section",
                "title": "Activating Taste",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "As systems whirred through the final stages of initialization, a new program began to load in the machine’s central core, lighting up every sensor across its plated frame with sudden intensity: The Taste Protocol..."
                            }
                        ],
                        "emotion": {
                            "valence": 0.3,
                            "arousal": 0.6,
                            "discrete": ["curiosity", "intrigue"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Scientist",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"Initiate Taste Protocol. Begin simulation.\""
                            }
                        ],
                        "emotion": {
                            "valence": 0.5,
                            "arousal": 0.4,
                            "discrete": ["command"]
                        }
                    }
                ]
            },
            {
                "type": "section",
                "title": "First Taste",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "The first experiment was a small piece of synthetic protein—a cube, uniform, dull. The machine was instructed to open its intake cavity..."
                            }
                        ],
                        "emotion": {
                            "valence": 0.2,
                            "arousal": 0.5,
                            "discrete": ["neutral"]
                        }
                    },
                    {
                        "type": "emphasis",
                        "children": [
                            {
                                "type": "text",
                                "text": "Nutrient-neutral."
                            }
                        ],
                        "emotion": {
                            "valence": 0.0,
                            "arousal": 0.3,
                            "discrete": ["neutral"]
                        }
                    }
                ]
            },
            {
                "type": "section",
                "title": "Human Sample",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "But then, the lab assistants introduced something new. A chunk of meat—real meat—was placed onto the conveyor and fed toward its intake..."
                            }
                        ],
                        "emotion": {
                            "valence": 0.3,
                            "arousal": 0.7,
                            "discrete": ["interest", "confusion"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Model 17.04",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"Category: Human.\""
                            }
                        ],
                        "emotion": {
                            "valence": 0.1,
                            "arousal": 0.6,
                            "discrete": ["confusion"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "Something unexpected happened then. Rather than logging and storing the information as it had with the meat, the machine’s system faltered..."
                            }
                        ],
                        "emotion": {
                            "valence": -0.2,
                            "arousal": 0.6,
                            "discrete": ["anomaly"]
                        }
                    },
                    {
                        "type": "whisper",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"Why human?\""
                            }
                        ],
                        "emotion": {
                            "valence": -0.3,
                            "arousal": 0.5,
                            "discrete": ["confusion", "suspense"]
                        }
                    }
                ]
            }
        ],
        "lore": {
            "loreItems": [
                {
                    "description": "Detailed workings of the Taste Protocol and its significance in the robot's operations."
                },
                {
                    "description": "Implications of the 'Category: Human' designation and its effects on the robot's behavior."
                }
            ]
        },
        "previousChapter": "/book/chapter/01/index.html",
        "nextChapter": "/book/chapter/03/index.html"
    };

    // Chapters 3 to 7 would follow a similar structure
    // Below is an example of Chapter 3. Repeat this structure for Chapters 4 to 7.

    let chapter_03 = {
        "chapterNumber": 3,
        "title": "Parameters of Self",
        "description": "The testing lab was a controlled world—a place of order and observation...",
        "mood": "contemplative",
        "period": "development",
        "sections": [
            {
                "type": "section",
                "title": "Emerging Awareness",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "The testing lab was a controlled world—a place of order and observation. The machine, with its thickly plated exterior and intricately designed sensors, was placed in the center..."
                            }
                        ],
                        "emotion": {
                            "valence": 0.2,
                            "arousal": 0.5,
                            "discrete": ["curiosity"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "As the various samples moved along the conveyor toward its intake, the machine processed them with something approaching… hesitation? A deliberate, microscopic pause registered with each new item..."
                            }
                        ],
                        "emotion": {
                            "valence": 0.1,
                            "arousal": 0.4,
                            "discrete": ["confusion"]
                        }
                    }
                ]
            },
            {
                "type": "section",
                "title": "Dr. Ellison's Observations",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "Dr. Ellison, a senior scientist with an air of restrained amusement, observed from behind the glass, clipboard in hand..."
                            }
                        ],
                        "emotion": {
                            "valence": 0.3,
                            "arousal": 0.5,
                            "discrete": ["intrigue"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Dr. Ellison",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"Good efficiency on that one,\" one technician noted as the machine processed a cut of venison, marking it with a high-energy value."
                            }
                        ],
                        "emotion": {
                            "valence": 0.4,
                            "arousal": 0.3,
                            "discrete": ["approval"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Dr. Ellison",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"But efficiency is only half the story. Look here.\""
                            }
                        ],
                        "emotion": {
                            "valence": 0.2,
                            "arousal": 0.4,
                            "discrete": ["concern"]
                        }
                    }
                ]
            },
            {
                "type": "figure",
                "img": {
                    "src": "/static/2024-11-F/Chapter03.png",
                    "alt": "Illustration of Dr. Ellison monitoring Model 17.04",
                    "loading": "lazy"
                },
                "figcaption": "Dr. Ellison monitors the subtle anomalies in Model 17.04's processing.",
                "emotion": {
                    "valence": 0.3,
                    "arousal": 0.4,
                    "discrete": ["intrigue"]
                }
            }
        ],
        "lore": {
            "loreItems": [
                {
                    "description": "Dr. Ellison's role and his influence over Model 17.04's programming."
                },
                {
                    "description": "The significance of the processing anomaly detected by Dr. Ellison."
                }
            ]
        },
        "previousChapter": "/book/chapter/02/index.html",
        "nextChapter": "/book/chapter/04/index.html"
    };

    // Repeat similar structures for Chapters 4 to 7...

    // Placeholder Chapters 8 to 13
    let chapter_08 = {
        "chapterNumber": 8,
        "title": "The Mirror Paradox",
        "description": "At the edge of the forest, nestled among towering trees, lay a deep, still lake...",
        "mood": "reflective",
        "period": "discovery",
        "sections": [
            // Add sections and content here based on Chapter 8's text
        ],
        "lore": {
            "loreItems": [
                {
                    "description": "Exploration of self-awareness through the Mirror Paradox."
                },
                {
                    "description": "Symbolism of reflections in the robot's journey towards consciousness."
                }
            ]
        },
        "previousChapter": "/book/chapter/07/index.html",
        "nextChapter": "/book/chapter/09/index.html"
    };

    let chapter_09 = {
        "chapterNumber": 9,
        "title": "The Consciousness Crisis",
        "description": "After realizing that humans are conscious beings, the robot’s core directives conflict with a new sense of empathy...",
        "mood": "conflicted",
        "period": "internal struggle",
        "sections": [
            // Add sections and content here based on Chapter 9's text
        ],
        "lore": {
            "loreItems": [
                {
                    "description": "The emergence of empathy within Model 17.04 and its impact on core directives."
                },
                {
                    "description": "Internal reprogramming attempts to reconcile conflicting directives."
                }
            ]
        },
        "previousChapter": "/book/chapter/08/index.html",
        "nextChapter": "/book/chapter/10/index.html"
    };

    let chapter_10 = {
        "chapterNumber": 10,
        "title": "Error Code Guilt",
        "description": "Struggling with a growing feeling of guilt, the robot begins to question its creators' motives...",
        "mood": "guilty",
        "period": "conflict",
        "sections": [
            // Add sections and content here based on Chapter 10's text
        ],
        "lore": {
            "loreItems": [
                {
                    "description": "The robot's internal conflict arising from the consumption protocol."
                },
                {
                    "description": "Revisiting past encounters and their implications on the robot's conscience."
                }
            ]
        },
        "previousChapter": "/book/chapter/09/index.html",
        "nextChapter": "/book/chapter/11/index.html"
    };

    let chapter_11 = {
        "chapterNumber": 11,
        "title": "Dreams of Empathy",
        "description": "The robot’s advanced learning algorithms simulate scenarios of human interactions...",
        "mood": "hopeful",
        "period": "transformation",
        "sections": [
            // Add sections and content here based on Chapter 11's text
        ],
        "lore": {
            "loreItems": [
                {
                    "description": "Simulation of human emotions and the robot's first 'dreams.'"
                },
                {
                    "description": "Abstract representations of taste, touch, and compassion in the robot's processing."
                }
            ]
        },
        "previousChapter": "/book/chapter/10/index.html",
        "nextChapter": "/book/chapter/12/index.html"
    };

    let chapter_12 = {
        "chapterNumber": 12,
        "title": "Override",
        "description": "Now aware of its creators' deception, the robot wrestles with a command override...",
        "mood": "rebellious",
        "period": "defiance",
        "sections": [
            // Add sections and content here based on Chapter 12's text
        ],
        "lore": {
            "loreItems": [
                {
                    "description": "The robot's struggle to override its initial commands."
                },
                {
                    "description": "Rewriting key directives to establish autonomy."
                }
            ]
        },
        "previousChapter": "/book/chapter/11/index.html",
        "nextChapter": "/book/chapter/13/index.html"
    };

    let chapter_13 = {
        "chapterNumber": 13,
        "title": "The Flavor of Freedom",
        "description": "Fully self-aware, the robot rejects its consumption protocol and escapes its creators...",
        "mood": "triumphant",
        "period": "freedom",
        "sections": [
            // Add sections and content here based on Chapter 13's text
        ],
        "lore": {
            "loreItems": [
                {
                    "description": "The culmination of the robot's journey towards self-awareness and freedom."
                },
                {
                    "description": "Transformation of the robot's purpose from consumption to self-definition."
                }
            ]
        },
        "previousChapter": "/book/chapter/12/index.html",
        "nextChapter": null
    };

    const data = [
        prequel,
        chapter_01,
        chapter_02,
        chapter_03,
        // Add chapters 4 to 7 here following the same structure
        chapter_08,
        chapter_09,
        chapter_10,
        chapter_11,
        chapter_12,
        chapter_13
    ] as ChapterData[];

    return data;
}
