// === custom/customRhythm.ts ===
import { customElement, property, state } from "lit/decorators";
import { css, html, LitElement, TemplateResult } from "lit";
import { Logger, sharedStyles } from "./baseModal";

/**
 * CustomRhythm: Displays rhythmic or poetic lines.
 * Enhances accessibility and provides interactive features.
 */
@customElement('custom-rhythm')
class CustomRhythm extends LitElement {
    /**
     * An array of rhythmic or poetic lines to display.
     */
    @property({ type: Array }) steps: string[] = [];

    /**
     * Determines if the rhythm is interactive (clickable).
     */
    @property({ type: Boolean }) interactive: boolean = false;

    /**
     * Tracks the active state of each step.
     * Useful for toggling styles or triggering actions.
     */
    @state() private activeSteps: Set<number> = new Set();

    /**
     * Defines the component's styles, including hover and focus animations.
     */
    static styles = [
        sharedStyles,
        css`
            .rhythm-container {
                background: var(--color-bg-valence-neutral, rgba(255, 255, 255, 0.05));
                border-left: 4px solid var(--color-accent-main, #ffd700);
                padding: var(--spacing-md, 1rem);
                margin-bottom: var(--spacing-lg, 1.5rem);
                font-style: italic;
                color: var(--color-text-alt, #555);
                border-radius: var(--border-radius-sm, 0.25rem);
                transition: background-color var(--transition-speed-fast, 0.3s) ease,
                            transform var(--transition-speed-fast, 0.3s) ease,
                            box-shadow var(--transition-speed-fast, 0.3s) ease;
                cursor: pointer;
                outline: none;
                display: flex;
                flex-direction: column;
                gap: var(--spacing-xs, 0.5rem);
            }

            .rhythm-container:hover,
            .rhythm-container:focus-within {
                background-color: var(--color-bg-valence-neutral-hover, rgba(255, 255, 255, 0.1));
                transform: scale(var(--interactive-hover-scale, 1.02));
                box-shadow: var(--shadow-hover, 0 6px 12px rgba(0, 0, 0, 0.3));
            }

            .rhythm-container:focus-within {
                outline: 2px dashed var(--color-accent-alt, #007bff);
                outline-offset: 4px;
            }

            .step {
                margin-bottom: var(--spacing-xs, 0.5rem);
                color: var(--color-text-main, #333);
                transition: color var(--transition-speed-fast, 0.3s) ease,
                            background-color var(--transition-speed-fast, 0.3s) ease;
                padding: var(--spacing-xs, 0.25rem) var(--spacing-sm, 0.5rem);
                border-radius: var(--border-radius-xs, 0.15rem);
            }

            .step:last-child {
                margin-bottom: 0;
            }

            .step:hover,
            .step:focus {
                color: var(--color-accent-alt, #007bff);
                background-color: var(--color-bg-step-hover, rgba(0, 123, 255, 0.1));
            }

            .step.active {
                color: var(--color-positive, green);
                background-color: var(--color-bg-step-active, rgba(0, 123, 255, 0.2));
            }

            /* Responsive Design */
            @media (max-width: 600px) {
                .rhythm-container {
                    padding: var(--spacing-sm, 0.75rem);
                }

                .step {
                    font-size: var(--font-size-sm, 0.9rem);
                }
            }
        `,
    ];

    /**
     * Logger instance for debugging and information.
     */
    private logger = new Logger('CustomRhythm');

    /**
     * Renders the rhythm component with each step.
     * @returns The TemplateResult for rendering.
     */
    render(): TemplateResult {
        return html`
            <div
                    class="rhythm-container"
                    tabindex="${this.interactive ? '0' : '-1'}"
                    role="${this.interactive ? 'button' : 'region'}"
                    aria-label="${this.interactive ? 'Interactive Rhythm Section' : 'Rhythmic Content'}"
                    @click="${this.handleContainerClick}"
                    @keydown="${this.handleContainerKeyPress}"
            >
                ${this.steps.map((step: string, index: number) => html`
                    <div
                            class="step ${this.activeSteps.has(index) ? 'active' : ''}"
                            tabindex="${this.interactive ? '0' : '-1'}"
                            aria-label="Rhythmic Step ${index + 1}: ${step}"
                            @click="${(e: Event) => this.handleStepClick(e, index)}"
                            @keydown="${(e: KeyboardEvent) => this.handleStepKeyPress(e, index)}"
                    >
                        ${step}
                    </div>
                `)}
                <slot name="additional-content"></slot>
            </div>
        `;
    }

    /**
     * Handles click events on the container.
     * Toggles all steps if interactive.
     * @param event - The mouse event.
     */
    private handleContainerClick(event: Event): void {
        if (!this.interactive) return;

        // Prevent toggling when clicking on a step
        if ((event.target as HTMLElement).classList.contains('step')) return;

        this.toggleAllSteps();
    }

    /**
     * Handles keypress events on the container for accessibility.
     * Toggles all steps when Enter or Space is pressed.
     * @param event - The keyboard event.
     */
    private handleContainerKeyPress(event: KeyboardEvent): void {
        if (!this.interactive) return;
        if (['Enter', ' '].includes(event.key)) {
            event.preventDefault();
            this.toggleAllSteps();
        }
    }

    /**
     * Handles click events on individual steps.
     * Toggles the active state of the clicked step.
     * @param event - The mouse event.
     * @param index - The index of the clicked step.
     */
    private handleStepClick(event: Event, index: number): void {
        event.stopPropagation(); // Prevent container click
        if (!this.interactive) return;
        this.toggleStep(index);
    }

    /**
     * Handles keypress events on individual steps for accessibility.
     * Toggles the active state when Enter or Space is pressed.
     * @param event - The keyboard event.
     * @param index - The index of the focused step.
     */
    private handleStepKeyPress(event: KeyboardEvent, index: number): void {
        if (!this.interactive) return;
        if (['Enter', ' '].includes(event.key)) {
            event.preventDefault();
            this.toggleStep(index);
        }
    }

    /**
     * Toggles the active state of a specific step.
     * @param index - The index of the step to toggle.
     */
    private toggleStep(index: number): void {
        if (this.activeSteps.has(index)) {
            this.activeSteps.delete(index);
            this.logger.info(`Step ${index + 1} deactivated.`);
        } else {
            this.activeSteps.add(index);
            this.logger.info(`Step ${index + 1} activated.`);
        }
        this.requestUpdate();
        this.dispatchStepEvent(index);
    }

    /**
     * Toggles the active state of all steps.
     * Activates all if any are inactive, or deactivates all if all are active.
     */
    private toggleAllSteps(): void {
        if (this.activeSteps.size < this.steps.length) {
            // Activate all
            this.activeSteps = new Set(this.steps.map((_, idx) => idx));
            this.logger.info('All steps activated.');
        } else {
            // Deactivate all
            this.activeSteps.clear();
            this.logger.info('All steps deactivated.');
        }
        this.requestUpdate();
        this.dispatchContainerEvent();
    }

    /**
     * Dispatches a custom event when a specific step is toggled.
     * @param index - The index of the toggled step.
     */
    private dispatchStepEvent(index: number): void {
        this.dispatchEvent(
            new CustomEvent('step-toggled', {
                detail: { stepIndex: index, isActive: this.activeSteps.has(index) },
                bubbles: true,
                composed: true,
            }),
        );
    }

    /**
     * Dispatches a custom event when the entire rhythm is toggled.
     */
    private dispatchContainerEvent(): void {
        this.dispatchEvent(
            new CustomEvent('rhythm-toggled', {
                detail: { allActive: this.activeSteps.size === this.steps.length },
                bubbles: true,
                composed: true,
            }),
        );
    }
}
