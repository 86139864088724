import {customElement, property} from "lit/decorators";
import {css, html, LitElement, TemplateResult} from "lit";
import {Logger} from "./baseModal";

/**
 * CustomProgress: A progress bar component to indicate loading or completion status.
 * Supports customizable colors and optional animation.
 */
@customElement('custom-progress')
class CustomProgress extends LitElement {
    @property({type: Number}) value: number = 0; // Current progress (0-100)
    @property({type: String}) color: string = 'var(--color-accent-main, #007bff)';
    @property({type: Boolean}) animated: boolean = false;

    static styles = css`
        :host {
            display: block;
            width: 100%;
            height: 20px;
            background-color: #e0e0e0;
            border-radius: var(--border-radius-sm, 4px);
            overflow: hidden;
            position: relative;
        }

        .progress-bar {
            height: 100%;
            width: 0%;
            background-color: var(--color-accent-main, #007bff);
            transition: width var(--transition-speed-fast, 0.3s) var(--transition-function, ease);
        }

        .progress-bar.animated {
            animation: progressAnimation 2s infinite;
        }

        @keyframes progressAnimation {
            from {
                width: 0%;
            }
            50% {
                width: 100%;
            }
            to {
                width: 0%;
            }
        }

        .label {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            color: #333;
            pointer-events: none;
        }
    `;

    private logger = new Logger('CustomProgress');

    render(): TemplateResult {
        return html`
            <div class="progress-bar ${this.animated ? 'animated' : ''}"
                 style="width: ${this.value}%; background-color: ${this.color};">
            </div>
            <div class="label">${this.value}%</div>
        `;
    }

    updated(changedProperties: Map<string, any>) {
        if (changedProperties.has('value')) {
            this.logger.info(`Progress updated to ${this.value}%.`);
            // Clamp value between 0 and 100
            if (this.value < 0) this.value = 0;
            if (this.value > 100) this.value = 100;
        }
    }
}