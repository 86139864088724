// === src/app/ui/index.ts ===
// Consolidated and Refactored Web Components with Enhanced Depth

import './custom/customDialogue'
import './custom/customModal'
import './custom/customProgress'
import './custom/customPuzzle'
import './custom/customQuiz'
import './custom/customQuizQuestion'
import './custom/customRating'
import './custom/customReflection'
import './custom/customRhythm'
import './custom/customTooltip'
import './custom/customWhisper'