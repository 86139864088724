import {css, html, LitElement, TemplateResult} from "lit";
import {customElement, property} from "lit/decorators";

/**
 * Logger: A simple logger utility for debugging and information purposes.
 * Replace or extend this with a more sophisticated logging mechanism as needed.
 */
export class Logger {
    private context: string;

    constructor(context: string) {
        this.context = context;
    }

    info(...args: any[]) {
        console.info(`[INFO] [${this.context}]:`, ...args);
    }

    debug(...args: any[]) {
        console.debug(`[DEBUG] [${this.context}]:`, ...args);
    }

    warn(...args: any[]) {
        console.warn(`[WARN] [${this.context}]:`, ...args);
    }

    error(...args: any[]) {
        console.error(`[ERROR] [${this.context}]:`, ...args);
    }
}

/**
 * SharedStyles: Common CSS styles shared across multiple components.
 */
export const sharedStyles = css`
    :host {
        display: block;
        cursor: pointer;
        outline: none;
    }

    .container {
        display: block;
        padding: 1rem;
        border-radius: 0.5rem;
        margin: 1.25rem 0;
        transition: transform 0.3s ease, background-color 0.3s ease;
        border: 2px solid rgba(0, 0, 0, 0.1);
        font-weight: bold;
    }

    .container:hover {
        transform: scale(1.03);
        background-color: rgba(0, 0, 0, 0.05);
    }

    .container:focus {
        outline: 2px solid #007bff;
    }

    .active {
        background-color: rgba(0, 123, 255, 0.1);
    }

    .hidden {
        display: none;
    }

    .error {
        color: red;
    }
`;

/**
 * BaseModal: A foundational modal component with core functionalities.
 * Handles accessibility features like focus trapping and Escape key handling.
 */
@customElement('base-modal')
export class BaseModal extends LitElement {
    @property({type: Boolean, reflect: true}) isOpen: boolean = false;
    @property({type: String}) title: string = '';
    @property({type: String}) size: 'small' | 'medium' | 'large' = 'medium';

    static styles = [
        sharedStyles,
        css`
            :host {
                display: none;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: var(--z-index-modal, 1000);
                align-items: center;
                justify-content: center;
                background: rgba(0, 0, 0, 0.5);
                transition: opacity 0.3s ease, visibility 0.3s ease;
            }

            :host([isOpen]) {
                display: flex;
                opacity: 1;
                visibility: visible;
            }

            .modal {
                background: var(--color-bg-content, #fff);
                border-radius: var(--border-radius-lg, 8px);
                padding: var(--spacing-lg, 1.5rem);
                width: 90%;
                max-width: 600px;
                box-shadow: var(--shadow-lg, 0 4px 6px rgba(0, 0, 0, 0.1));
                position: relative;
                animation: fadeIn 0.3s ease-out;
            }

            .modal.small {
                max-width: 300px;
            }

            .modal.medium {
                max-width: 600px;
            }

            .modal.large {
                max-width: 900px;
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: var(--spacing-md, 1rem);
            }

            .title {
                font-size: var(--font-size-h2, 1.5rem);
                margin: 0;
                color: var(--color-text-main, #000);
            }

            .close-button {
                background: transparent;
                border: none;
                font-size: 1.5rem;
                cursor: pointer;
                color: var(--color-text-alt, #666);
                transition: color 0.3s ease;
            }

            .close-button:hover,
            .close-button:focus {
                color: var(--color-accent-alt, #007bff);
            }

            .content {
                font-size: var(--font-size-body, 1rem);
                color: var(--color-text-content, #333);
            }

            @keyframes fadeIn {
                from {
                    opacity: 0;
                    transform: translateY(-20px);
                }
                to {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        `,
    ];

    private logger = new Logger('BaseModal');
    private previouslyFocusedElement: HTMLElement | null = null;

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('keydown', this.handleKeyDown);
    }

    disconnectedCallback() {
        document.removeEventListener('keydown', this.handleKeyDown);
        super.disconnectedCallback();
    }

    render(): TemplateResult {
        return html`
            <div
                    class="modal ${this.size}"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-title"
                    @click="${this.handleOutsideClick}"
            >
                <div class="header">
                    <h2 id="modal-title" class="title">${this.title}</h2>
                    <button
                            class="close-button"
                            aria-label="Close Modal"
                            @click="${this.closeModal}"
                    >
                        &times;
                    </button>
                </div>
                <div class="content">
                    <slot></slot>
                </div>
            </div>
        `;
    }

    updated(changedProperties: Map<string, any>) {
        if (changedProperties.has('isOpen')) {
            if (this.isOpen) {
                this.previouslyFocusedElement = document.activeElement as HTMLElement;
                this.dispatchEvent(new CustomEvent('modal-opened', {bubbles: true, composed: true}));
                this.focusTrap();
            } else {
                this.dispatchEvent(new CustomEvent('modal-closed', {bubbles: true, composed: true}));
                this.restoreFocus();
            }
            this.logger.info(`Modal is now ${this.isOpen ? 'open' : 'closed'}.`);
        }
    }

    private handleKeyDown = (event: KeyboardEvent): void => {
        if (this.isOpen && event.key === 'Escape') {
            this.closeModal();
        }
    };

    private handleOutsideClick(event: Event): void {
        if ((event.target as HTMLElement).classList.contains('modal')) {
            this.closeModal();
        }
    }

    private closeModal(): void {
        this.isOpen = false;
    }

    private focusTrap(): void {
        const focusableElements = this.shadowRoot?.querySelectorAll<HTMLElement>(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
        );
        const firstElement = focusableElements?.[0];
        const lastElement = focusableElements?.[focusableElements.length - 1];

        const handleTab = (e: KeyboardEvent) => {
            if (e.key !== 'Tab') return;

            if (e.shiftKey) {
                if (document.activeElement === firstElement) {
                    e.preventDefault();
                    lastElement?.focus();
                }
            } else {
                if (document.activeElement === lastElement) {
                    e.preventDefault();
                    firstElement?.focus();
                }
            }
        };

        // @ts-ignore
        this.shadowRoot?.addEventListener('keydown', handleTab);
        firstElement?.focus();
    }

    private restoreFocus(): void {
        this.previouslyFocusedElement?.focus();
    }
}