// === custom/customPuzzle.ts ===
import { customElement, property, state } from "lit/decorators";
import { css, html, LitElement, TemplateResult } from "lit";
import { Logger, sharedStyles } from "./baseModal";

/**
 * CustomPuzzle: A reusable puzzle component.
 * Represents a puzzle element that users can interact with, indicating whether it's been played.
 */
@customElement('custom-puzzle')
class CustomPuzzle extends LitElement {
    @property({ type: Boolean, reflect: true }) isPlayed = false;
    @property({ type: String, reflect: true }) puzzleId = '';
    @property({ type: String, reflect: true }) difficulty = 'medium';
    @property({ type: String, reflect: true }) hint = '';

    @state() private isFocused = false;

    static styles = [
        sharedStyles,
        css`
            .container {
                background: var(--color-bg-content, rgba(255, 165, 0, 0.1));
                border: 2px solid var(--color-negative, red);
                padding: var(--spacing-md, 1rem);
                border-radius: var(--border-radius-sm, 4px);
                transition: background-color 0.3s ease, transform 0.3s ease;
                cursor: pointer;
                outline: none;
            }

            .container.active {
                background: var(--color-bg-valence-neutral, rgba(255, 165, 0, 0.2));
            }

            .container:hover,
            .container:focus {
                transform: scale(var(--interactive-hover-scale, 1.05));
                box-shadow: var(--shadow-hover, 0 6px 12px rgba(0, 0, 0, 0.3));
            }

            .info {
                margin-top: var(--spacing-sm, 0.5rem);
                font-size: var(--font-size-caption, 0.9rem);
                color: var(--color-text-alt, #555);
            }

            .info p {
                margin: 0.25rem 0;
            }

            /* Animation for state toggle */
            .container {
                animation: fadeIn 0.3s ease-in-out;
            }

            @keyframes fadeIn {
                from {
                    opacity: 0.8;
                }
                to {
                    opacity: 1;
                }
            }

            /* Focus Styles */
            :host(:focus) .container {
                outline: 2px dashed var(--color-accent-alt, #007bff);
                outline-offset: 4px;
            }
        `,
    ];

    private logger = new Logger('CustomPuzzle');

    render(): TemplateResult {
        return html`
            <div
                    class="container ${this.isPlayed ? 'active' : ''}"
                    tabindex="0"
                    role="button"
                    aria-pressed="${this.isPlayed}"
                    aria-label="Puzzle ${this.puzzleId} - Difficulty: ${this.difficulty}"
                    @click="${this.togglePlay}"
                    @keydown="${this.handleKeyPress}"
                    @focus="${this.onFocus}"
                    @blur="${this.onBlur}"
            >
                <slot></slot>
                <div class="info">
                    <p><strong>Difficulty:</strong> ${this.difficulty}</p>
                    ${this.hint ? html`<p><strong>Hint:</strong> ${this.hint}</p>` : ''}
                </div>
            </div>
        `;
    }

    private togglePlay(): void {
        this.isPlayed = !this.isPlayed;
        this.logger.info('Puzzle state toggled:', this.isPlayed);
        this.dispatchEvent(
            new CustomEvent('element-clicked', {
                detail: { type: 'custom-puzzle', state: this.isPlayed ? 'played' : 'default' },
                bubbles: true,
                composed: true,
            }),
        );
    }

    private handleKeyPress(event: KeyboardEvent): void {
        if (['Enter', ' '].includes(event.key)) {
            event.preventDefault();
            this.togglePlay();
        }
    }

    private onFocus(): void {
        this.isFocused = true;
    }

    private onBlur(): void {
        this.isFocused = false;
    }
}
