// === custom/customDialogue.ts ===
import { customElement, property, state } from "lit/decorators";
import { css, html, LitElement, TemplateResult } from "lit";
import { Logger, sharedStyles } from "./baseModal";
import { Emotion } from "../../../data/types"; // Updated import path

/**
 * CustomDialogue: Displays a dialogue with a speaker and their message.
 * Enhances accessibility and provides interactive features.
 */
@customElement('custom-dialogue')
export class CustomDialogue extends LitElement {
    /**
     * The name of the speaker in the dialogue.
     */
    @property({ type: String }) speaker: string = 'Unknown';

    /**
     * The message text of the dialogue.
     */
    @property({ type: String }) text: string = '';

    /**
     * Determines if the dialogue is interactive (clickable).
     */
    @property({ type: Boolean }) interactive: boolean = false;

    /**
     * Optional avatar image URL for the speaker.
     */
    @property({ type: String }) avatar: string = '';

    /**
     * Emotion object to apply styles based on emotional state.
     */
    @property({ type: Object }) emotion: Emotion | null = null;

    /**
     * Optional array of dialogue lines.
     */
    @property({ type: Array }) lines: { text: string; action: () => void }[] = [];

    /**
     * Tracks if the dialogue is currently focused.
     */
    @state() private isFocused: boolean = false;

    /**
     * Tracks if the message is expanded.
     */
    @state() private isExpanded: boolean = false;

    /**
     * Defines the component's styles.
     */
    static styles = [
        sharedStyles,
        css`
            .dialogue-container {
                /* Existing styles */
                background: linear-gradient(135deg, var(--color-bg-valence-neutral, rgba(135, 206, 250, 0.1)), var(--color-bg-content, rgba(135, 206, 250, 0.15)));
                transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
            }
            .avatar {
                max-width: 50px;
            }

            .dialogue-container.positive {
                background: linear-gradient(135deg, var(--color-bg-valence-positive, rgba(144, 238, 144, 0.2)), var(--color-bg-content, rgba(144, 238, 144, 0.25)));
                border-left-color: var(--color-primary-positive, #28a745);
            }

            .dialogue-container.negative {
                background: linear-gradient(135deg, var(--color-bg-valence-negative, rgba(255, 99, 71, 0.2)), var(--color-bg-content, rgba(255, 99, 71, 0.25)));
                border-left-color: var(--color-primary-negative, #dc3545);
            }

            .dialogue-container.neutral {
                background: linear-gradient(135deg, var(--color-bg-valence-neutral, rgba(135, 206, 250, 0.1)), var(--color-bg-content, rgba(135, 206, 250, 0.15)));
                border-left-color: var(--color-primary-neutral, #007bff);
            }

            .message {
                transition: max-height 0.3s ease, color 0.3s ease;
                position: relative;
                padding-right: var(--spacing-md, 1rem);
            }

            .message::after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 1.5em;
                background: linear-gradient(to top, var(--color-bg-valence-neutral, rgba(135, 206, 250, 0.1)), transparent);
                display: var(--show-gradient, block);
                pointer-events: none;
                transition: opacity 0.3s ease;
            }

            .message.expanded::after {
                display: none;
            }

            .lines {
                margin-top: var(--spacing-md, 1rem);
                display: flex;
                flex-direction: column;
                gap: var(--spacing-sm, 0.5rem);
            }

            .line-button {
                padding: var(--spacing-sm, 0.5rem) var(--spacing-md, 1rem);
                background-color: var(--color-primary, #007bff);
                color: var(--color-text-content, #fff);
                border: none;
                border-radius: var(--border-radius-sm, 0.25rem);
                cursor: pointer;
                transition: background-color var(--transition-speed-fast, 0.3s) ease,
                transform var(--transition-speed-fast, 0.3s) ease,
                box-shadow var(--transition-speed-fast, 0.3s) ease;
                font-size: var(--font-size-button, 1rem);
                box-shadow: var(--shadow-sm, 0 2px 4px rgba(0, 0, 0, 0.1));
            }

            .line-button:hover,
            .line-button:focus {
                background-color: var(--color-primary-dark, #0056b3);
                transform: translateY(-1px);
                box-shadow: var(--shadow-lg, 0 4px 8px rgba(0, 0, 0, 0.2));
            }

            .line-button:active {
                transform: translateY(0);
                box-shadow: var(--shadow-sm, 0 2px 4px rgba(0, 0, 0, 0.1));
            }
        `,
    ];

    private logger = new Logger('CustomDialogue');

    render(): TemplateResult {
        return html`
            <div
                    class="dialogue-container ${this.getEmotionClasses()}"
                    tabindex="${this.interactive ? '0' : '-1'}"
                    role="${this.interactive ? 'button' : 'dialog'}"
                    aria-labelledby="dialogue-speaker"
                    aria-describedby="dialogue-message"
                    @click="${this.handleClick}"
                    @keydown="${this.handleKeyPress}"
                    @focus="${this.handleFocus}"
                    @blur="${this.handleBlur}"
            >
                ${this.avatar
                        ? html`<img
                                src="${this.avatar}"
                                alt="${this.speaker}'s avatar"
                                class="avatar"
                                @click="${this.handleAvatarClick}"
                                tabindex="${this.interactive ? '0' : '-1'}"
                                aria-label="${this.speaker}'s avatar"
                        />`
                        : ''}
                <div class="content">
                    <div id="dialogue-speaker" class="speaker">${this.speaker}</div>
                    <div
                            id="dialogue-message"
                            class="message ${this.isExpanded ? 'expanded' : ''}"
                            aria-expanded="${this.isExpanded}"
                    >
                        ${this.text}
                    </div>
                    ${this.lines.length > 0
                            ? html`
                                <div class="lines" role="group" aria-label="Dialogue Lines  ">
                                    ${this.lines.map(
                                            (line, index) => html`
                                                <button
                                                        class="line-button"
                                                        @click="${() => this.handleLineClick(index)}"
                                                        aria-label="Choice ${index + 1}: ${line.text}"
                                                >
                                                    ${line.text}
                                                </button>
                                            `,
                                    )}
                                </div>
                            `
                            : ''}
                </div>
            </div>
        `;
    }

    private handleClick(): void {
        if (!this.interactive) return;
        this.logger.info(`Dialogue by "${this.speaker}" clicked.`);
        this.dispatchEvent(
            new CustomEvent('element-clicked', {
                detail: { type: 'custom-dialogue', state: 'clicked' },
                bubbles: true,
                composed: true,
            }),
        );
    }

    private handleAvatarClick(event: Event): void {
        event.stopPropagation();
        if (!this.interactive) return;
        this.logger.info(`Avatar of "${this.speaker}" clicked.`);
        this.dispatchEvent(
            new CustomEvent('avatar-clicked', {
                detail: { type: 'custom-dialogue', speaker: this.speaker },
                bubbles: true,
                composed: true,
            }),
        );
    }

    private handleKeyPress(event: KeyboardEvent): void {
        if (!this.interactive) return;
        if (['Enter', ' '].includes(event.key)) {
            event.preventDefault();
            this.handleClick();
        }
    }

    private handleFocus(): void {
        this.isFocused = true;
    }

    private handleBlur(): void {
        this.isFocused = false;
    }

    private handleLineClick(index: number): void {
        const line = this.lines[index];
        if (line && typeof line.action === 'function') {
            line.action();
            this.logger.info(`Choice "${line.text}" selected.`);
            this.dispatchEvent(
                new CustomEvent('line-selected', {
                    detail: { line: line.text, index },
                    bubbles: true,
                    composed: true,
                }),
            );
        }
    }

    /**
     * Determines and returns appropriate emotion-based classes.
     * @returns A string containing CSS classes based on the current emotion.
     */
    private getEmotionClasses(): string {
        if (!this.emotion) return '';

        const { valence, arousal, discrete } = this.emotion;
        const classes = [];

        // Valence-based classes
        if (valence > 5) {
            classes.push('positive');
        } else if (valence < 5) {
            classes.push('negative');
        } else {
            classes.push('neutral');
        }

        // Arousal-based classes
        if (arousal > 7) {
            classes.push('high-arousal');
        } else if (arousal < 3) {
            classes.push('low-arousal');
        } else {
            classes.push('medium-arousal');
        }

        // Discrete emotion classes
        discrete.forEach((emotionType) => {
            const sanitizedEmotion = this.sanitizeEmotion(emotionType);
            if (sanitizedEmotion) {
                classes.push(`emotion-${sanitizedEmotion}`);
            }
        });

        return classes.join(' ');
    }

    /**
     * Sanitizes emotion types to ensure valid class names.
     * @param emotionType - The raw emotion type string.
     * @returns A sanitized emotion type string.
     */
    private sanitizeEmotion(emotionType: string): string {
        return emotionType.replace(/[^a-z0-9\-]/gi, '').toLowerCase();
    }
}
