// === src/data/types.ts ===

/**
 * Enum representing the keys for predefined emotions.
 */
export enum EmotionKey {
    Calm = "calm",
    Magical = "magical",
    Hope = "hope",
    Inspiration = "inspiration",
    Energy = "energy",
    Joy = "joy",
    Sadness = "sadness",
    Anger = "anger",
    Surprise = "surprise",
    Fear = "fear",
    Disgust = "disgust",
    Love = "love",
    // Newly added emotions to support enhanced core.ts
    Wisdom = "wisdom",
    Mystery = "mystery",
    Doubt = "doubt",
    Realization = "realization",
    Unity = "unity",
    Wonder = "wonder",
    Strength = "strength",
    // Add more as needed
}

/**
 * Interface representing the emotional attributes of an element.
 */
export interface Emotion {
    /**
     * Valence represents the positivity or negativity of the emotion.
     * Range: 0 (negative) to 10 (positive)
     */
    valence: number;

    /**
     * Arousal represents the intensity of the emotion.
     * Range: 0 (calm) to 10 (intense)
     */
    arousal: number;

    /**
     * Discrete emotions categorize the emotion into specific types.
     * Example values: ['happy', 'sad', 'angry']
     */
    discrete: string[];
}

/**
 * Enum for loading strategies for images.
 */
export type LoadingStrategy = 'lazy' | 'eager' | 'auto';

/**
 * Interface for image-related data.
 */
export interface ImageData {
    /** Source URL of the image */
    src: string;

    /** Alternative text for the image */
    alt?: string;

    /** Loading strategy for the image */
    loading?: LoadingStrategy;
}

/**
 * Base interface for all content data types.
 */
export interface BaseContentData {
    /** Type identifier for the content element */
    type: string;

    /** Optional emotional attributes */
    emotion?: Emotion;

    /** Optional child content elements */
    children?: ContentData[];
}

/**
 * Interface for content data that supports named slots.
 */
export interface SlottedContentData extends BaseContentData {
    /**
     * Specifies the slot name where the child element should be projected.
     * If not provided, the element defaults to the unnamed (default) slot.
     */
    slot?: string;
}

/**
 * Interface for plain text content.
 */
export interface TextData extends BaseContentData {
    type: 'text';
    text: string;
}

/**
 * Interface for paragraph content.
 */
export interface ParagraphData extends BaseContentData {
    type: 'paragraph';
    children: ContentData[];
}

/**
 * Interface for whisper content (e.g., soft-spoken dialogue).
 */
export interface WhisperData extends BaseContentData {
    type: 'whisper';
    text: TextData[];
}

/**
 * Interface for rhythm content, representing rhythmic text or patterns.
 */
export interface RhythmData extends BaseContentData {
    type: 'rhythm';
    steps: string[];
}

/**
 * Interface for figure content, including images and optional captions.
 */
export interface FigureData extends BaseContentData {
    type: 'figure';
    title?: string;
    src: string;
    alt?: string;
    loading?: LoadingStrategy;
    caption?: string;
}

/**
 * Interface for emphasizing text (e.g., bold, italics).
 */
export interface EmphasisData extends BaseContentData {
    type: 'emphasis';
    tag?: 'em' | 'strong' | 'u' | 'mark';
    text: string;
}

/**
 * Enum representing interaction types for interactive elements.
 */
export enum InteractionType {
    Click = 'click',
    Hover = 'hover',
    Drag = 'drag',
    // Add more as needed
}

/**
 * Interface for dialogue content.
 */
export interface DialogueData extends BaseContentData {
    type: 'dialogue';
    avatar?: string;
    speaker: string;
    lines: { text: string; action?: () => void }[];
    children?: ContentData[];
}

/**
 * Interface for custom reflection elements, allowing user input.
 */
export interface CustomReflectionData extends SlottedContentData {
    type: 'custom-reflection';
    reflectionId: string;
    prompt: string;
    onSubmit?: string; // Identifier for submit action
}

/**
 * Interface for custom puzzle elements, enabling interactive puzzles.
 */
export interface CustomPuzzleData extends SlottedContentData {
    type: 'custom-puzzle';
    puzzleId: string;
    difficulty?: 'easy' | 'medium' | 'hard';
    hint?: string;
    onSolve?: string; // Identifier for solve action
}

/**
 * Interface for custom mirror elements, possibly for reflective content.
 */
export interface CustomMirrorData extends SlottedContentData {
    type: 'custom-mirror';
    mirrorId: string;
    reflectionText?: string;
    onToggle?: string; // Identifier for toggle action
}

/**
 * Interface for quiz questions.
 */
export interface QuizQuestion {
    /** The quiz question text */
    question: string;

    /** Array of answer options */
    options: string[];

    /** Index of the correct answer in the options array */
    correctIndex: number;
}

/**
 * Interface for custom quiz elements.
 */
export interface CustomQuizData extends BaseContentData {
    type: 'custom-quiz';
    questions: QuizQuestion[];
    onComplete?: string; // Identifier for completion action
}

/**
 * Interface for custom tooltip elements.
 */
export interface CustomTooltipData extends BaseContentData {
    type: 'custom-tooltip';
    text: string;
    position?: 'top' | 'bottom' | 'left' | 'right';
}

/**
 * Interface for custom rating elements.
 */
export interface CustomRatingData extends BaseContentData {
    type: 'custom-rating';
    max?: number; // Maximum rating value
    value?: number; // Current rating value
    readonly?: boolean; // If true, rating cannot be changed by the user
}

/**
 * Interface for list items.
 */
export type ListItem = TextListItem | ButtonData | LinkData | EmphasisData;

/**
 * Interface for text list items.
 */
export interface TextListItem extends BaseContentData {
    type: 'text';
    text: string;
}

/**
 * Interface for list content (ordered or unordered).
 */
export interface ListData extends BaseContentData {
    type: 'list';
    ordered: boolean;
    items: ListItem[];
}

/**
 * Interface for hyperlink content.
 */
export interface LinkData extends BaseContentData {
    type: 'link';
    href: string;
    text: string;
    target?: '_blank' | '_self' | '_parent' | '_top';
}

/**
 * Interface for button content.
 */
export interface ButtonData extends BaseContentData {
    type: 'button';
    text: string;
    action: string; // Identifier for the action to be performed on click
    payload?: any;
}

/**
 * Interface for navigation content, typically containing links.
 */
export interface NavigationData extends BaseContentData {
    type: 'navigation';
    children: (LinkData | TextData)[];
}

/**
 * Interface for section content, acting as containers for other content elements.
 */
export interface SectionData extends BaseContentData {
    type: 'section';
    title?: string;
    children: ContentData[];
}

/**
 * Interface for custom modal elements.
 */
export interface CustomModalData extends SlottedContentData {
    type: 'custom-modal';
    modalId: string;
    title: string;
    size?: 'small' | 'medium' | 'large';
    isOpen?: boolean;
    onClose?: string; // Identifier for close action
    children?: ContentData[];
}

/**
 * Interface for carousel item content if they contain nested content.
 */
export interface CarouselItemContentData extends BaseContentData {
    type: 'carousel-item';
    content: ContentData;
}

/**
 * Interface for custom carousel elements.
 */
export interface CustomCarouselData extends BaseContentData {
    type: 'custom-carousel';
    items: Array<string | CarouselItemContentData>; // Array of image URLs or nested content
    interval?: number; // Auto-slide interval in milliseconds
    showIndicators?: boolean;
    showArrows?: boolean;
    onItemChange?: string; // Identifier for item change action
}

/**
 * Interface for custom progress elements.
 */
export interface CustomProgressData extends BaseContentData {
    type: 'custom-progress';
    value: number; // Current progress value (0-100)
    color?: string; // Color of the progress bar
    max?: number;
    animated?: boolean; // If true, progress bar animates
    onComplete?: string; // Identifier for completion action
}

/**
 * Interface for interactive elements with dynamic actions.
 */
export interface InteractiveElementData extends BaseContentData {
    type: 'interactive-element';
    interactionType: InteractionType;
    actions: Array<{
        action: string; // Identifier for the action
        payload?: any;  // Optional payload for the action
    }>;
}

/**
 * Alias for content data used as text content.
 */
export type TextContent = TextData;

/**
 * Alias for content data used as sub sections.
 */
export type SubSection = ContentData | SectionData | WhisperData | DialogueData;

/**
 * Union type encompassing all possible content data interfaces.
 */
export type ContentData =
    | SectionData
    | TextData
    | ParagraphData
    | WhisperData
    | RhythmData
    | FigureData
    | EmphasisData
    | DialogueData
    | CustomReflectionData
    | CustomPuzzleData
    | CustomMirrorData
    | ListData
    | LinkData
    | ButtonData
    | NavigationData
    | CustomQuizData
    | CustomTooltipData
    | CustomRatingData
    | CustomModalData
    | CustomCarouselData
    | CustomProgressData
    | CarouselItemContentData
    | InteractiveElementData;

/**
 * Interface representing a single lore item.
 */
export interface LoreItem {
    description: string;
}

/**
 * Interface for lore data, containing multiple lore items.
 */
export interface LoreData {
    loreItems: LoreItem[];
}

/**
 * Interface representing the complete data structure for a chapter.
 */
export interface ChapterData {
    chapterNumber: number;
    title: string;
    description: string;
    mood: string;
    period: string;
    tags?: string[];    // Tags for categorization
    themes?: string[];  // Themes present in the chapter
    sections: Section[];
    lore: LoreData;
    previousChapter: string | null;
    nextChapter: string | null;
}

export type Section = SectionData | NavigationData;

/**
 * Additional Types for the Entire Scene
 */

/**
 * Interface for carousel item content.
 */
export type CarouselItemContent = CarouselItemContentData;

/**
 * Interface for interactive elements within the scene.
 */
export type InteractiveElement = InteractiveElementData;
