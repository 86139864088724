import {ChapterData} from "./types";


export function Boonberry(chapterNumber: number): ChapterData {
    return {
        "chapterNumber": chapterNumber, // Utilize the function parameter
        "title": "Boof and the Boonberry Fool",
        "description": "Chapter 1 of Lore.Land: Boof and the Boonberry Fool. Join Boof on her quest to find the elusive Boonberry Fool, unraveling ancient mysteries and forging new friendships along the way.",
        "mood": "boon",
        "period": "spring",
        "sections": [
            {
                "type": "section",
                "title": "The Beginning",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "As the first light of dawn painted the sky with hues of pink and gold, Boof set out from her quaint cottage, her heart brimming with anticipation. The scent of blooming boonberries filled the air, guiding her towards the sprawling grove where the Boonberry Fool was said to dwell."
                            }
                        ],
                        "emotion": {
                            "valence": 0.6,
                            "arousal": 0.7,
                            "discrete": ["excitement", "hope"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "Boof wandered through the lush fields, her footsteps soft against the dewy grass. The grove beckoned with its enchanting allure, a place where reality seemed to blend with magic."
                            }
                        ],
                        "emotion": {
                            "valence": 0.5,
                            "arousal": 0.6,
                            "discrete": ["curiosity"]
                        }
                    },
                    {
                        "type": "whisper",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"Where could it be?\" Boof whispered to the gentle breeze, her voice barely audible over the "
                            }
                        ],
                        "emotion": {
                            "valence": 0.3,
                            "arousal": 0.4,
                            "discrete": ["suspense"]
                        }
                    },
                    {
                        "type": "rhythm",
                        "text": "rustling leaves",
                        "emotion": {
                            "valence": -0.5,
                            "arousal": 0.3,
                            "discrete": ["melancholy"]
                        }
                    },
                    {
                        "type": "text",
                        "text": "."
                    },
                    {
                        "type": "figure",
                        "img": {
                            "src": "/static/2024-11-B/01.png",
                            "alt": "Illustration of Boof wandering through the boonberry fields",
                            "loading": "lazy"
                        },
                        "figcaption": "Boof begins her journey through the shimmering boonberry fields.",
                        "emotion": {
                            "valence": 0.6,
                            "arousal": 0.5,
                            "discrete": ["intrigue"]
                        }
                    }
                ]
            },
            {
                "type": "section",
                "title": "A Radiant Discovery",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "At the heart of the grove, Boof stumbled upon a boonberry unlike any other. Its deep blue hue shimmered under the canopy's dappled sunlight, casting mesmerizing patterns on the ground."
                            }
                        ],
                        "emotion": {
                            "valence": 0.7,
                            "arousal": 0.6,
                            "discrete": ["awe"]
                        }
                    },
                    {
                        "type": "emphasis",
                        "children": [
                            {
                                "type": "text",
                                "text": "This one stood out among the rest, more dazzling and vibrant."
                            },
                            {
                                "type": "custom-reflection",
                                "slot": "reflection", // Assign to 'reflection' slot
                                "children": [
                                    {
                                        "type": "text",
                                        "text": "Boof reached out tentatively, marveling at its beauty, but the Fool was nowhere in sight."
                                    }
                                ],
                                "emotion": {
                                    "valence": 0.2,
                                    "arousal": 0.4,
                                    "discrete": ["thoughtful"]
                                }
                            }
                        ],
                        "emotion": {
                            "valence": 0.7,
                            "arousal": 0.6,
                            "discrete": ["curiosity"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Boof",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"Is this the Fool?\" she mused, gazing intently at the radiant boonberry."
                            }
                        ],
                        "emotion": {
                            "valence": 0.4,
                            "arousal": 0.5,
                            "discrete": ["anticipation"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "Just then, a shadow danced between the trees—a fleeting glimpse that ignited a spark of hope within Boof."
                            }
                        ],
                        "emotion": {
                            "valence": 0.5,
                            "arousal": 0.6,
                            "discrete": ["hope"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Voice of the Fool",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"Catch me if you can,\" the Fool’s voice echoed from the distance, teasing Boof onward."
                            }
                        ],
                        "emotion": {
                            "valence": 0.6,
                            "arousal": 0.7,
                            "discrete": ["playfulness"]
                        }
                    },
                    {
                        "type": "figure",
                        "img": {
                            "src": "/static/2024-11-B/02.png",
                            "alt": "Illustration of the elusive Boonberry Fool",
                            "loading": "lazy"
                        },
                        "figcaption": "The elusive Boonberry Fool appears amidst the boonberry grove.",
                        "emotion": {
                            "valence": 0.6,
                            "arousal": 0.5,
                            "discrete": ["intrigue"]
                        }
                    }
                ]
            },
            {
                "type": "section",
                "title": "The Guardian's Challenge",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "Determined to uncover the truth, Boof ventured deeper into the grove. The path twisted and turned, leading her to a secluded clearing bathed in ethereal light."
                            }
                        ],
                        "emotion": {
                            "valence": 0.5,
                            "arousal": 0.6,
                            "discrete": ["determination"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "In the center stood an ancient stone fountain, its waters crystal clear and bubbling with an otherworldly energy. Surrounding the fountain were intricate carvings depicting scenes of joy, laughter, and playful antics—emblems of the Fool's mischievous nature."
                            }
                        ],
                        "emotion": {
                            "valence": 0.6,
                            "arousal": 0.5,
                            "discrete": ["awe"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Guardian",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"Welcome, Boof,\" a melodious voice echoed from behind the fountain. Boof turned to see a figure cloaked in shimmering robes, their face obscured by a playful mask adorned with boonberry motifs. \"You've done well to find this place. But the journey ahead will require more than just perseverance.\""
                            }
                        ],
                        "emotion": {
                            "valence": 0.4,
                            "arousal": 0.5,
                            "discrete": ["authority"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Boof",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"Who are you?\" she asked, stepping closer."
                            }
                        ],
                        "emotion": {
                            "valence": 0.3,
                            "arousal": 0.4,
                            "discrete": ["curiosity"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Guardian",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"I am the Guardian of the Boonberry Fool. To understand the Fool, you must first understand the magic that binds our grove. Are you ready to embrace the challenge?\""
                            }
                        ],
                        "emotion": {
                            "valence": 0.5,
                            "arousal": 0.6,
                            "discrete": ["challenge"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "Without hesitation, Boof nodded. \"I'm ready.\" She pulled out her mystical wand, the symbol of her lineage glowing softly."
                            }
                        ],
                        "emotion": {
                            "valence": 0.7,
                            "arousal": 0.7,
                            "discrete": ["courage"]
                        }
                    },
                    {
                        "type": "figure",
                        "img": {
                            "src": "/static/2024-11-B/03.png",
                            "alt": "Illustration of the Guardian and Boof at the fountain",
                            "loading": "lazy"
                        },
                        "figcaption": "Boof meets the Guardian of the Boonberry Fool.",
                        "emotion": {
                            "valence": 0.6,
                            "arousal": 0.5,
                            "discrete": ["intrigue"]
                        }
                    }
                ]
            },
            {
                "type": "section",
                "title": "Uncover the Fool’s Secret",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "The Guardian extended a hand towards Boof, and a shimmering portal opened, revealing a labyrinth of towering boonberry bushes illuminated by soft, golden light."
                            }
                        ],
                        "emotion": {
                            "valence": 0.5,
                            "arousal": 0.6,
                            "discrete": ["anticipation"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"Within this labyrinth lie the enchanted boonberries, each holding a fragment of the Fool's essence. To unlock the grove's true magic, you must gather them all,\" the Guardian explained."
                            }
                        ],
                        "emotion": {
                            "valence": 0.4,
                            "arousal": 0.5,
                            "discrete": ["instruction"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Boof",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"What challenges await me on this quest?\" Boof asked, determination etched on her face."
                            }
                        ],
                        "emotion": {
                            "valence": 0.6,
                            "arousal": 0.6,
                            "discrete": ["determination"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Guardian",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"Each boonberry will test a different aspect of your spirit—courage, wisdom, kindness, and more. Embrace each challenge, and you will uncover the secrets of the Boonberry Fool.\""
                            }
                        ],
                        "emotion": {
                            "valence": 0.5,
                            "arousal": 0.6,
                            "discrete": ["guidance"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "With the Guardian's words echoing in her mind, Boof stepped into the portal, ready to face whatever lay ahead."
                            }
                        ],
                        "emotion": {
                            "valence": 0.7,
                            "arousal": 0.8,
                            "discrete": ["resolve"]
                        }
                    },
                    {
                        "type": "custom-puzzle",
                        "slot": "puzzle", // Assign to 'puzzle' slot
                        "children": [
                            {
                                "type": "text",
                                "text": "Boof found herself before a grand puzzle carved into the stone archway. Intricate symbols danced across the surface, each representing a different boonberry."
                            }
                        ],
                        "emotion": {
                            "valence": 0.5,
                            "arousal": 0.6,
                            "discrete": ["challenge"]
                        }
                    },
                    {
                        "type": "button",
                        "text": "Attempt the Puzzle",
                        "action": "attemptPuzzle",
                        "emotion": {
                            "valence": 0.6,
                            "arousal": 0.7,
                            "discrete": ["engagement"]
                        }
                    }
                ]
            },
            {
                "type": "section",
                "title": "Boof's Profile Story Timeline",
                "children": [
                    {
                        "type": "list",
                        "ordered": true,
                        "items": [
                            {
                                "type": "text",
                                "text": "The Quest Begins: Boof sets out to find the Boonberry Fool, encountering the shimmering boonberries and mysterious shadows."
                            },
                            {
                                "type": "text",
                                "text": "A Radiant Discovery: Boof discovers a particularly radiant boonberry, sparking her curiosity and determination."
                            },
                            {
                                "type": "text",
                                "text": "The Guardian's Challenge: Boof meets the Guardian of the Boonberry Fool and accepts the quest to uncover the grove's secrets."
                            },
                            {
                                "type": "text",
                                "text": "Uncovering the Labyrinth: Boof enters the enchanted labyrinth to gather the enchanted boonberries, each revealing a piece of the Fool's essence."
                            },
                            {
                                "type": "text",
                                "text": "Facing the Puzzle: Boof encounters a mystical puzzle that tests her courage and wisdom."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "navigation",
                "children": [
                    {
                        "type": "link",
                        "href": "/book/chapter/13/index.html",
                        "text": "← Previous Chapter"
                    },
                    {
                        "type": "text",
                        "text": " | "
                    },
                    {
                        "type": "link",
                        "href": "/book/chapter/02/index.html",
                        "text": "Next Chapter →"
                    }
                ]
            },
            {
                "type": "section",
                "title": "Advance Story",
                "children": [
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "As Boof delved deeper into the labyrinth, each boonberry she collected revealed not just fragments of the Fool's essence, but also memories of the grove's ancient past."
                            }
                        ],
                        "emotion": {
                            "valence": 0.6,
                            "arousal": 0.7,
                            "discrete": ["nostalgia"]
                        }
                    },
                    {
                        "type": "custom-mirror",
                        "slot": "mirror", // Assign to 'mirror' slot
                        "children": [
                            {
                                "type": "text",
                                "text": "Boof looked into the shimmering waters of the stone fountain and saw visions of joyous gatherings and playful antics, all orchestrated by the Boonberry Fool."
                            }
                        ],
                        "emotion": {
                            "valence": 0.8,
                            "arousal": 0.5,
                            "discrete": ["joy"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "With each step, the labyrinth grew more challenging, testing Boof's resolve and teaching her the true meaning of harmony and balance."
                            }
                        ],
                        "emotion": {
                            "valence": 0.5,
                            "arousal": 0.6,
                            "discrete": ["perseverance"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "Finally, Boof reached the heart of the labyrinth, where the final boonberry awaited. Its glow was more radiant than any before, pulsating with the combined essence of all the gathered boonberries."
                            }
                        ],
                        "emotion": {
                            "valence": 0.7,
                            "arousal": 0.8,
                            "discrete": ["triumph"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Boof",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"I've done it,\" she exclaimed, her voice filled with relief and accomplishment."
                            }
                        ],
                        "emotion": {
                            "valence": 0.8,
                            "arousal": 0.7,
                            "discrete": ["satisfaction"]
                        }
                    },
                    {
                        "type": "dialogue",
                        "speaker": "Guardian",
                        "children": [
                            {
                                "type": "text",
                                "text": "\"You have shown great courage and wisdom, Boof. The Boonberry Fool's magic is now fully awakened, restoring harmony to the grove.\""
                            }
                        ],
                        "emotion": {
                            "valence": 0.6,
                            "arousal": 0.6,
                            "discrete": ["approval"]
                        }
                    },
                    {
                        "type": "paragraph",
                        "children": [
                            {
                                "type": "text",
                                "text": "With the grove's magic restored, the Boonberry Fool materialized in full form, its presence radiating warmth and joy. Boof felt a sense of fulfillment wash over her, knowing that her journey had not only uncovered ancient secrets but also transformed her."
                            }
                        ],
                        "emotion": {
                            "valence": 0.9,
                            "arousal": 0.6,
                            "discrete": ["joy"]
                        }
                    },
                    {
                        "type": "figure",
                        "img": {
                            "src": "/static/2024-11-B/04.png",
                            "alt": "Illustration of Boof and the Boonberry Fool in the restored grove",
                            "loading": "lazy"
                        },
                        "figcaption": "Boof stands alongside the Boonberry Fool, the grove flourishing around them.",
                        "emotion": {
                            "valence": 0.9,
                            "arousal": 0.5,
                            "discrete": ["contentment"]
                        }
                    }
                ]
            },
            {
                "type": "section",
                "title": "End of Chapter 01: Boof and the Boonberry Fool",
                "children": [
                    {
                        "type": "navigation",
                        "children": [
                            {
                                "type": "link",
                                "href": "/book/chapter/13/index.html",
                                "text": "← Previous Chapter"
                            },
                            {
                                "type": "text",
                                "text": " | "
                            },
                            {
                                "type": "link",
                                "href": "/book/chapter/02/index.html",
                                "text": "Next Chapter →"
                            }
                        ]
                    }
                ]
            }
        ],
        "lore": {
            "loreItems": [
                {
                    "description": "The origin of the Boonberry Fool and its significance to Lore.Land."
                },
                {
                    "description": "Boof's lineage and her innate connection to the magic of the grove."
                },
                {
                    "description": "Historical events that shaped the relationship between the Guardians and the Boonberry Fool."
                }
            ]
        },
        "previousChapter": "/book/chapter/13/index.html",
        "nextChapter": "/book/chapter/02/index.html"
    };
}
