// === custom/customRating.ts ===
import { customElement, property, state } from "lit/decorators";
import { css, html, LitElement, TemplateResult } from "lit";
import { Logger } from "./baseModal";

/**
 * CustomRating: Allows users to rate content using stars or other symbols.
 * Supports interactive rating with hover effects and readonly mode.
 */
@customElement('custom-rating')
class CustomRating extends LitElement {
    @property({ type: Number }) max: number = 5;
    @property({ type: Number }) value: number = 0;
    @property({ type: Boolean }) readonly: boolean = false;
    @property({ type: String }) symbol: string = '★'; // Can be customized (e.g., '♥', '👍')

    @state() private hoverValue: number = 0;

    static styles = css`
        :host {
            display: inline-block;
            --star-color: var(--color-text-alt, #ccc);
            --star-filled-color: var(--color-positive, #ffca08);
            --star-hover-color: var(--color-accent-alt, #007bff);
        }

        .star {
            font-size: 1.5rem;
            color: var(--star-color);
            cursor: pointer;
            transition: color 0.2s, transform 0.2s;
            user-select: none;
        }

        .filled {
            color: var(--star-filled-color);
        }

        .hovered {
            color: var(--star-hover-color);
        }

        .readonly .star {
            cursor: default;
        }

        .star:focus {
            outline: 2px solid var(--color-accent-alt, #007bff);
            outline-offset: 2px;
        }
    `;

    private logger = new Logger('CustomRating');

    render(): TemplateResult {
        const stars = [];
        for (let i = 1; i <= this.max; i++) {
            let className = 'star';
            if (this.hoverValue >= i) {
                className += ' hovered';
            } else if (!this.hoverValue && this.value >= i) {
                className += ' filled';
            }

            stars.push(html`
                <span
                        class="${className}"
                        @click="${() => this.setValue(i)}"
                        @mouseover="${() => this.setHover(i)}"
                        @mouseout="${this.clearHover}"
                        @focus="${() => this.setHover(i)}"
                        @blur="${this.clearHover}"
                        aria-label="Rate ${i} out of ${this.max}"
                        role="radio"
                        tabindex="${this.readonly ? '-1' : '0'}"
                >
                    ${this.symbol}
                </span>
            `);
        }

        return html`
            <div
                    class="${this.readonly ? 'readonly' : ''}"
                    role="radiogroup"
                    aria-label="Rating"
            >
                ${stars}
            </div>
        `;
    }

    private setValue(val: number): void {
        if (this.readonly) return;
        this.value = val;
        this.logger.info(`Rating set to ${val}`);
        this.dispatchEvent(
            new CustomEvent('rating-changed', {
                detail: { value: this.value },
                bubbles: true,
                composed: true,
            }),
        );
    }

    private setHover(val: number): void {
        if (this.readonly) return;
        this.hoverValue = val;
    }

    private clearHover(): void {
        if (this.readonly) return;
        this.hoverValue = 0;
    }
}
